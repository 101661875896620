import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const orgforms = {
  // Organizational form-related functions
  getAllOrgForms: (limit, offset, search_text) =>
    Axiosinstance.get(API_URL_CONSTANTS.GET_ALL_ORG_FORMS_API_URL, {
      params: {
        limit,
        offset,
        search_text,
      },
    }),
  getOrgForm: (formId) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.ORGANIZATION_FORM_API_URL}${formId}`
    ),

  createOrgForm: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.ORGANIZATION_FORM_API_URL, data),
  getOrgFormNames: (limit, offset, search_text) =>
    Axiosinstance.get(API_URL_CONSTANTS.ORG_FORM_NAMES_API_URL, {
      params: {
        limit,
        offset,
        search_text,
      },
    }),
};

export { orgforms };
