import { WifiOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import React from "react";
import "./Offlinepage.scss"; // Make sure to import the CSS file

const Offlinepage = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="center-screen">
      <Result
        icon={<WifiOutlined style={{ fontSize: 72 }} />}
        title="You're offline"
        subTitle="Please check your internet connection and try again."
        extra={
          <Button type="primary" onClick={handleReload}>
            Retry
          </Button>
        }
      />
    </div>
  );
};

export default Offlinepage;
