import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const orgvitalSigns = {
  // Organizational form-related functions
  getAllOrgvitalSigns: (limit, offset, search_text) =>
    Axiosinstance.get(API_URL_CONSTANTS.ORG_VITAL_SIGN_ALL_API_URL, {
      params: {
        limit,
        offset,
        search_text,
      },
    }),
  getOrgVitalSignById: (Id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.ORG_VITAL_SIGN_API_URL}/${Id}`),

  createOrgvitalSigns: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.ORGANIZATION_FORM_API_URL, data),
  updateOrgvitalSigns: (id, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.ORG_VITAL_SIGN_API_URL}/${id}`,
      data
    ),
};

export { orgvitalSigns };
