let token = "";
let idtoken = "";
const settoken = (value) => {
  token = value;
};
const setIdtoken = (value) => {
  idtoken = value;
};
const gettoken = () => {
  return token;
};
const getidtoken = () => {
  return idtoken;
};

// write phonenumer vaidation
export { getidtoken, gettoken, setIdtoken, settoken };
