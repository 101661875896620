// AppConstants.js
export let TENANT_ADMIN_ID = null;
export let PHYSICIAN_ID = null;
export let MONITORING_STAFF_ID = null;

export const updateRoleIds = () => {
  TENANT_ADMIN_ID = getRoleId("TENANT_ADMIN");
  PHYSICIAN_ID = getRoleId("PHYSICIAN");
  MONITORING_STAFF_ID = getRoleId("MONITORING_STAFF");
};

const getRoleId = (roleName) => {
  const id = localStorage.getItem(`${roleName}_ID`);
  return id ? Number(id) : null;
};

// Export the role IDs
// export const PHYSICIAN_ID = physicianRole ? physicianRole.id : null;
// export const MONITORING_STAFF_ID = monitoringStaffRole
//   ? monitoringStaffRole.id
//   : null;
export const buildVersion = process.env.REACT_APP_BUILD_VERSION;
