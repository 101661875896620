import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { fecthrefreshToken } from "./Auth/AuthSlice";
import rootReducer from "./rootReducer";

// const middlewares = [thunk];
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "episode", "settings", "notifications"],
};
const persistRoot = JSON.parse(localStorage.getItem("persist:root") || "{}");
const refresh =
  JSON.parse(persistRoot?.auth || "{}").profileinfo?.refresh ?? "";

// / Define a custom middleware to handle 401 errors
const handle401ErrorsMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (
      action?.payload?.message === "Token has expired." ||
      action?.payload?.response?.status === 400
    ) {
      const failedRequest = {
        config: action?.payload?.config, // The original Axios request configuration
        onSuccessAction: action?.payload?.config?.onSuccessAction, // Store the success action type
      };
      return dispatch(fecthrefreshToken(failedRequest));
    }

    return next(action);
  };

export default handle401ErrorsMiddleware;
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(handle401ErrorsMiddleware),
});

const persistor = persistStore(store);
export { persistor, store };
