import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const patient = {
  getpatientList: ({
    limit,
    offset,
    search_text,
    sort_by,
    order,
    episode_type_id,
    staff,
    physician,
    site_id,
    patient_status,
    episode_status,
    no_active_episode,
    rpm_likely_to_record_vital,
    rpm_not_recorded_in_last_three,
    rpm_not_recorded_this_month,
    rpm_completed_this_month,
    rpm_patient_consent,
    bhi_patient_consent,
    physician_consent,
    device_pending_setup_status,
    device_setup_completed_but_vitals,
    pending_tms,
    completed_tms,
    pending_cmt,
    completed_cmt,
    bhi_completed_assessment,
    bhi_pending_assessment,
    apply_careplan_filter,
    device_setup_completed_this_month,
    is_vitals_recorded,
  }) =>
    Axiosinstance.get(API_URL_CONSTANTS.PATIENT_API_URL, {
      params: {
        limit,
        offset,
        search_text,
        sort_by,
        order,
        episode_type_id,
        staff,
        physician,
        site_id,
        patient_status,
        episode_status,
        no_active_episode,
        rpm_likely_to_record_vital,
        rpm_not_recorded_in_last_three,
        rpm_not_recorded_this_month,
        rpm_completed_this_month,
        rpm_patient_consent,
        bhi_patient_consent,
        physician_consent,
        device_pending_setup_status,
        device_setup_completed_but_vitals,
        pending_tms,
        completed_tms,
        pending_cmt,
        completed_cmt,
        bhi_completed_assessment,
        bhi_pending_assessment,
        apply_careplan_filter,
        device_setup_completed_this_month,
        is_vitals_recorded: is_vitals_recorded,
      },
    }),
  createpatient: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.PATIENT_API_URL, data),
  getpatientinfo: (id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.PATIENT_API_URL}${id}`),
  deletepatientInfo: (id) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.PATIENT_API_URL}${id}`),
  dischargepatientInfo: (id, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.PATIENT_DISCHARGE_API_URL}/${id}`,
      data
    ),
  markPatientNotOnboarded: (id, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.PATIENT_NOT_ONBOARDED_API_URL}/${id}`,
      data
    ),
  updatepatientInfo: (id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.PATIENT_API_URL}${id}`, data),
  createEpisode: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.PATIENT_EPISODE_API_URL, data),
  updateEpisodeInfo: (id, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_API_URL}${id}`,
      data
    ),
  updateEpisodeAssignCareplan: (data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_ASSIGN_CAREPLAN_API_URL}`,
      data
    ),
  getEpisodeInfo: (id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.PATIENT_EPISODE_API_URL}${id}`),
  getEpisodeList: (limit, offset, id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.PATIENT_EPISODE_API_URL}all/${id}`, {
      params: {
        limit,
        offset,
      },
    }),
  // PATIENT_FORMS_API_URL
  getPatientFormsList: (id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.PATIENT_FORMS_API_URL}${id}`),

  // PATIENT_EPISODE_LOG_ALL_API_URL
  getEpisodeLogList: (limit, offset, id) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_LOG_ALL_API_URL}${id}`,
      {
        params: {
          limit,
          offset,
        },
      }
    ),

  getEpisodeLogActivityList: (limit, offset, id) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_LOG_ACTIVITY_ALL_API_URL}`,
      {
        params: {
          limit,
          offset,
          id,
        },
      }
    ),
  getDeviceSetupTypesList: () =>
    Axiosinstance.get(API_URL_CONSTANTS.DEVICE_SETUP_TYPES_ALL_API_URL),
  getDeviceTypesList: () =>
    Axiosinstance.get(API_URL_CONSTANTS.ALL_DEVICE_TYPES_API_URL),

  CreateDeviceSetupTypesList: (setupData) =>
    Axiosinstance.post(API_URL_CONSTANTS.DEVICE_SETUP_API_URL, setupData),
  getDeviceSetupTasks: (episodeId) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.DEVICE_SETUP_TASKS_ALL_API_URL}${episodeId}`
    ),

  updateDeviceSetupTask: (id, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_DEVICE_SETUP_TASK_API_URL}/${id}`,
      data
    ),
  createEpisodeLog: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.PATIENT_EPISODE_LOG_API_URL, data),
  updateEpisodeLog: (id, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_LOG_API_URL}${id}`,
      data
    ),
  getpatientVitalinfo: (id, vital_sign_id, start_date, end_date) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_VITAL_TREND_API_URL}/${id}`,
      {
        params: {
          start_date,
          end_date,
          vital_sign_id,
        },
      }
    ),
  getEpisodeLogInfo: (id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.PATIENT_EPISODE_LOG_API_URL}${id}`),
  deleteEpisodeLogInfo: (id) =>
    Axiosinstance.delete(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_LOG_API_URL}${id}`
    ),

  getEpisodeInterventionFormCompleted: (id) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_CAREPLAN_INTERVENTION_FORM_COMPLETED_API_URL}${id}`
    ),
  updateEpisodeCarePlanInfo: (data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.ASSIGN_CAREPLAN_TO_PATIENT_EPISODE_API_URL}`,
      data
    ),

  getPatientAssessment: (patientId) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_ASSESSMENT_API_URL}/${patientId}`
    ),

  getAssessmentTrendData: (patientId, start_date, end_date, form_id) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_ASSESSMENT_TREND_API_URL}${patientId}`,
      {
        params: {
          start_date,
          end_date,
          form_id,
        },
      }
    ),

  // Get information about a specific device
  getEpisodeCarePlanlist: (Id) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_CAREPLAN_LIST_API_URL}/${Id}`
    ),

  // Function to get patient episode care plan information by episodeId
  getPatientEpisodeCarePlan: (episodeId) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_CAREPLAN_API_URL}/${episodeId}`
    ),
  updatePatientEpisodeCarePlan: (episodeId, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_CAREPLAN_API_URL}/${episodeId}`,
      data
    ),
  suspendPatientEpisode: (episodeId, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.SUSPEND_PATIENT_EPISODE_API_URL}${episodeId}`,
      data
    ),
  // Create device for a patient
  createDevice: (data) =>
    Axiosinstance.post(`${API_URL_CONSTANTS.DEVICE_API_URL}`, data),

  // Get all devices for a patient
  getpatientDevices: (limit, offset, patientId) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.DEVICE_API_URL}all/${patientId}`),

  // Get information about a specific device
  getDeviceInfo: (deviceId) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.DEVICE_API_URL}${deviceId}`),

  // Update information about a specific device
  updateDeviceInfo: (deviceId, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.DEVICE_API_URL}${deviceId}`, data),

  // Delete a specific device
  deleteDeviceInfo: (deviceId) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.DEVICE_API_URL}${deviceId}`),

  // Create allergy for a patient
  createAllergy: (data) =>
    Axiosinstance.post(`${API_URL_CONSTANTS.ALLERGIES_API_URL}`, data),

  // Get all allergies for a patient
  getpatientAllergies: (patientId) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.ALLERGIES_API_URL}all/${patientId}`),

  // Get information about a specific allergy
  getAllergyInfo: (allergyId) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.ALLERGIES_API_URL}${allergyId}`),

  // Update information about a specific allergy
  updateAllergyInfo: (allergyId, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.ALLERGIES_API_URL}${allergyId}`,
      data
    ),

  // Delete a specific allergy
  deleteAllergyInfo: (allergyId) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.ALLERGIES_API_URL}${allergyId}`),

  // Create contact for a patient
  createContact: (data) =>
    Axiosinstance.post(`${API_URL_CONSTANTS.CONTACT_API_URL}`, data),

  // Get all contacts for a patient
  getpatientContacts: (patientId) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.CONTACT_API_URL}all/${patientId}`),

  // Get information about a specific contact
  getContactInfo: (contactId) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.CONTACT_API_URL}${contactId}`),

  // Update information about a specific contact
  updateContactInfo: (contactId, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.CONTACT_API_URL}${contactId}`, data),

  // Delete a specific contact
  deleteContactInfo: (contactId) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.CONTACT_API_URL}${contactId}`),

  createGuardian: (data) =>
    Axiosinstance.post(`${API_URL_CONSTANTS.GUARDIAN_API_URL}`, data),

  // Get all guardians for a patient
  getpatientGuardians: (patientId) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.GUARDIAN_API_URL}all/${patientId}`),

  // Get information about a specific guardian
  getGuardianInfo: (guardianId) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.GUARDIAN_API_URL}${guardianId}`),

  // Update information about a specific guardian
  updateGuardianInfo: (guardianId, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.GUARDIAN_API_URL}${guardianId}`,
      data
    ),

  // Delete a specific guardian
  deleteGuardianInfo: (guardianId) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.GUARDIAN_API_URL}${guardianId}`),

  checkPatient: (firstname, lastname) =>
    Axiosinstance.get(API_URL_CONSTANTS.PATIENT_CHECK_API_URL, {
      params: {
        firstname,
        lastname,
      },
    }),
};
export { patient };
