import React from "react";
import { Dropdown, Space } from "antd";
import CustomButton from "../CustomButton";
import "./CustomDropdown.scss";

const CustomDropdown = ({
  buttonStyle,
  menuItems,
  onMenuClick,
  onOpenChange,
  open,
  children,
  overlayClassName,
}) => {
  const dropdownProps = {
    overlayClassName,
    menu: {
      items: menuItems,
      onClick: onMenuClick,
    },
    onOpenChange,
    open,
  };

  // if (buttonStyle) {
  //   return (
  //     <div className="custom_dropdown_btn_block">
  //       <Dropdown {...dropdownProps}>
  //         <CustomButton>
  //           <Space>{children}</Space>
  //         </CustomButton>
  //       </Dropdown>
  //     </div>
  //   );
  // }

  return (
    <Dropdown
      {...dropdownProps}
      getPopupContainer={(trigger) => trigger.parentNode}
      // open={true}
    >
      {children}
    </Dropdown>
  );
};

export default CustomDropdown;
