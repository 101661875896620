import CryptoJS from "crypto-js";

const DEFAULT_ALGORITHM = CryptoJS.AES;
const DEFAULT_SECRET_KEY = "your_default_secret_key"; // Replace with your actual default secret key

// Encrypt data with options for algorithm and secret key
export const encryptData = (
  data,
  secretKey = DEFAULT_SECRET_KEY,
  algorithm = DEFAULT_ALGORITHM
) => {
  try {
    return algorithm.encrypt(JSON.stringify(data), secretKey).toString();
  } catch (error) {
    console.error("Encryption error:", error);
    return null;
  }
};

// Decrypt data with options for algorithm and secret key
export const decryptData = (
  encryptedData,
  secretKey = DEFAULT_SECRET_KEY,
  algorithm = DEFAULT_ALGORITHM
) => {
  try {
    const bytes = algorithm.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
};

// Save encrypted data to local storage with custom storage key
export const saveEncryptedData = (
  key,
  data,
  secretKey = DEFAULT_SECRET_KEY,
  algorithm = DEFAULT_ALGORITHM
) => {
  const encryptedData = encryptData(data, secretKey, algorithm);
  if (encryptedData) {
    localStorage.setItem(key, encryptedData);
  }
};

// Retrieve and decrypt data from local storage with custom storage key
export const getDecryptedData = (
  key,
  secretKey = DEFAULT_SECRET_KEY,
  algorithm = DEFAULT_ALGORITHM
) => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    return decryptData(encryptedData, secretKey, algorithm);
  }
  return null;
};

// Clear local storage for a specific key
export const clearLocalStorageKey = (key) => {
  localStorage.removeItem(key);
};

// Clear all items from local storage
export const clearLocalStorage = () => {
  localStorage.clear();
};
