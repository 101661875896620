import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  drawerOpen: false,
};

// Create a slice
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    // Reducer function to handle drawer opening
    openDrawer(state) {
      state.drawerOpen = true;
    },
    // Reducer function to handle drawer closing
    closeDrawer(state) {
      state.drawerOpen = false;
    },
  },
});

// Export actions
export const { openDrawer, closeDrawer } = commonSlice.actions;

// Export reducer
export default commonSlice.reducer;
