// Constants
import API_URL_CONSTANTS from "../constants/APIEndpoints";

// Axios instance
import Axiosinstance from "../config/config";

const episodeService = {
  getEpisodeTypes: () =>
    Axiosinstance.get(API_URL_CONSTANTS.EPISODE_TYPES_API_URL),
};

export { episodeService };
