import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { episodeService } from "../../services/episode";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice"; // Import loading state actions

export const fetchEpisodeTypes = createAsyncThunk(
  "patient/fetchEpisodeTypes",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse()); // Set loading state to true
    try {
      const response = await episodeService.getEpisodeTypes();
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      //   return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  episodeTypes: [],
  loading: false,
  error: null,
};

const episodeSlice = createSlice({
  name: "episode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEpisodeTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEpisodeTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.episodeTypes = action.payload;
      })
      .addCase(fetchEpisodeTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default episodeSlice.reducer;
