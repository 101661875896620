import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const CustomSpin = ({ spinning, color, size }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: spinning ? "block" : "none",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        zIndex: 9999,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Spin
          spinning={spinning}
          size={size}
          indicator={
            <LoadingOutlined
              style={{ fontSize: size === "large" ? 60 : 24, color }}
            />
          }
        />
      </div>
    </div>
  );
};

export default CustomSpin;
