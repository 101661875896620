import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomNotification from "../../components/common/CustomNotification";
import { notificationservice } from "../../services/notification";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

export const fetchNotificationList = createAsyncThunk(
  "notifications/fetchList",
  async ({ limit, offset, notification_status, priority_type }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await notificationservice.getNotifications(
        limit,
        offset,
        notification_status,
        priority_type
      );
      thunkAPI.dispatch(setLoadingFalse());
      return response.data; // Return the response data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      //   return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
  }
);
export const fetchNotificationCount = createAsyncThunk(
  "notifications/fetchCount",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await notificationservice.getNotificationCount();
      thunkAPI.dispatch(setLoadingFalse());
      return response.data; // Return the response data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
  }
);

export const fetchPatientNotifications = createAsyncThunk(
  "notifications/fetchPatientNotifications",
  async (
    {
      patientId,
      limit,
      offset,
      notification_status,
      priority_type,
      episode_type_id,
      handlerouter,
    },
    thunkAPI
  ) => {
    //
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await notificationservice.getPatientNotifications(
        patientId,
        limit,
        offset,
        notification_status,
        priority_type,
        episode_type_id
      );

      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const updateNotification = createAsyncThunk(
  "notification/updateNotification",
  async ({ id, data, handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue()); // Set loading state to true
    try {
      const response = await notificationservice.updateNotifications(id, data);
      CustomNotification(
        "success",
        "Alert Closed",
        "The alert has been closed successfully."
      );
      handlerouter();
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false
      return response.data; // Return the updated notification data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false in case of error
      return thunkAPI.rejectWithValue(error.response.data); // Reject with value to trigger the 'rejected' action
    }
  }
);

const initialState = {
  notifications: [],
  notificationscount: undefined,
  notificationsPatient: [],
  loading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationList.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
      })
      .addCase(fetchNotificationList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchNotificationCount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNotificationCount.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationscount = action.payload;
      })
      .addCase(fetchNotificationCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPatientNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatientNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationsPatient = action.payload;
      })
      .addCase(fetchPatientNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default notificationSlice.reducer;
