import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomNotification from "../../components/common/CustomNotification";
import { report } from "../../services/report";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

export const fetchCmtCompletedReports = createAsyncThunk(
  "report/fetchCmtCompletedReports",
  async ({ physician_id, month, year, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await report.getCmtreport(physician_id, month, year);
      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();

        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const fetchCompleteDevicedReports = createAsyncThunk(
  "report/fetchCompleteDevicedReports",
  async ({ physician_id, month, year, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await report.getDevicereport(physician_id, month, year);
      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();

        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const fetchTMSTimeReports = createAsyncThunk(
  "report/fetchTMSTimeReports",
  async ({ physician_id, month, year, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await report.getTimeTmsreport(physician_id, month, year);
      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();

        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const fetchTMSTimeReportsA = createAsyncThunk(
  "report/fetchTMSTimeReportsA",
  async ({ physician_id, month, year, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await report.getTimeTms_A_report(
        physician_id,
        month,
        year
      );
      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();

        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const fetchTMSTimeReportsB = createAsyncThunk(
  "report/fetchTMSTimeReportsB",
  async ({ physician_id, month, year, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await report.getTimeTms_B_report(
        physician_id,
        month,
        year
      );
      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();

        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const fetchVitalSignsMonitoringReports = createAsyncThunk(
  "report/fetchVitalSignsMonitoringReports",
  async ({ physician_id, month, year, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await report.getVitalSigsmonitoringReport(
        physician_id,
        month,
        year
      );
      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();

        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const fetchpatientsDurationReports = createAsyncThunk(
  "report/fetchpatientsDurationReports",
  async ({ physician_id, month, year, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await report.getPatientsDurationreport(
        physician_id,
        month,
        year
      );
      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();

        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error);
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const fetchpatientsOnboardedReports = createAsyncThunk(
  "report/fetchpatientsOnboardedReports",
  async ({ physician_id, month, year, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await report.getPatientsOnboardedreport(
        physician_id,
        month,
        year
      );
      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();

        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error);
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const shareReport = createAsyncThunk(
  "report/shareReport",
  async (
    { to_email, subject, content, link, type, handlenavigate },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await report.shareReport({
        to_email,
        subject,
        content,
        link,
        type: capitalizeFirstLetter(type),
      });
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("success", "Success", "Report shared successfully");
      handlenavigate(); // Assuming handlenavigate is a function to handle navigation
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const fetchDevicesList = createAsyncThunk(
  "devices/fetchDevicesList",
  async ({ limit, offset, status, type, search_text }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await report.getDevicesList(search_text);
      thunkAPI.dispatch(setLoadingFalse());

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchBillingReport = createAsyncThunk(
  "report/fetchBillingReport",
  async ({ physician_id, month, year, siteName, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await report.getBillingReport(
        physician_id,
        month,
        year,
        siteName
      );
      handlenavigate();

      thunkAPI.dispatch(setLoadingFalse());

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("error", "Error", "Failed to fetch billing report");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    completedCmtreports: [],
    completedDevicereports: [],
    devicesList: [],
    TmsTimeReport: [],
    TmsTimeA: [],
    TmsTimeB: [],
    VitalSignsMonitoring: [],
    patientsDurationreports: [],
    Onboardedpatients: [],
    billingReport: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetreportDataState(state) {
      // Reset only the specified part of the state based on action.payload
      state.completedCmtreports = [];
      state.completedDevicereports = [];
      state.TmsTimeReport = [];
      state.TmsTimeA = [];
      state.TmsTimeB = [];
      state.VitalSignsMonitoring = [];
      state.Onboardedpatients = [];
      state.patientsDurationreports = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCmtCompletedReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCmtCompletedReports.fulfilled, (state, action) => {
        state.loading = false;
        state.completedCmtreports = action.payload;
      })
      .addCase(fetchCmtCompletedReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchBillingReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBillingReport.fulfilled, (state, action) => {
        state.loading = false;
        state.billingReport = action.payload;
      })
      .addCase(fetchBillingReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchTMSTimeReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTMSTimeReports.fulfilled, (state, action) => {
        state.loading = false;
        state.TmsTimeReport = action.payload;
      })
      .addCase(fetchTMSTimeReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTMSTimeReportsA.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTMSTimeReportsA.fulfilled, (state, action) => {
        state.loading = false;
        state.TmsTimeA = action.payload;
      })
      .addCase(fetchTMSTimeReportsA.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTMSTimeReportsB.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTMSTimeReportsB.fulfilled, (state, action) => {
        state.loading = false;
        state.TmsTimeB = action.payload;
      })
      .addCase(fetchTMSTimeReportsB.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchVitalSignsMonitoringReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVitalSignsMonitoringReports.fulfilled, (state, action) => {
        state.loading = false;
        state.VitalSignsMonitoring = action.payload;
      })
      .addCase(fetchVitalSignsMonitoringReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchCompleteDevicedReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompleteDevicedReports.fulfilled, (state, action) => {
        state.loading = false;
        state.completedDevicereports = action.payload;
      })
      .addCase(fetchCompleteDevicedReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchpatientsDurationReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchpatientsDurationReports.fulfilled, (state, action) => {
        state.loading = false;
        state.patientsDurationreports = action.payload;
      })
      .addCase(fetchpatientsDurationReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchpatientsOnboardedReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchpatientsOnboardedReports.fulfilled, (state, action) => {
        state.loading = false;
        state.Onboardedpatients = action.payload;
      })
      .addCase(fetchpatientsOnboardedReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDevicesList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDevicesList.fulfilled, (state, action) => {
        state.loading = false;
        state.devicesList = action.payload;
      })
      .addCase(fetchDevicesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { resetreportDataState } = reportsSlice.actions;
export default reportsSlice.reducer;
