import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { dashboardervice } from "../../services/dashboard";
import { dashboardervice } from "../../services/dashboard";
import { setLoadingFalse, setLoadingTrue } from "../SpinSlice/SpinSlice";

// Import loading state actions

export const fetchQuickViewDashboard = createAsyncThunk(
  "dashboard/fetchQuickViewDashboard",
  async ({ _ }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue()); // Set loading state to true
    try {
      const response = await dashboardervice.getdashboardsinfo();
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false
      return response.data; // Return the fetched quick view dashboard data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false in case of error
      return thunkAPI.rejectWithValue(error.response.data); // Reject with value to trigger the 'rejected' action
    }
  }
);
export const fetchDashboardMetrics = createAsyncThunk(
  "dashboard/fetchDashboardMetrics",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue()); // Set loading state to true
    try {
      const response = await dashboardervice.getdashboardsmetrics();
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false
      return response.data; // Return the dashboard metrics data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false in case of error
      return thunkAPI.rejectWithValue(error.response.data); // Reject with value to trigger the 'rejected' action
    }
  }
);
const initialState = {
  quickViewDashboardData: null,
  dashboardMetrics: null,
  loading: false,
  error: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuickViewDashboard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchQuickViewDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.quickViewDashboardData = action.payload;
      })
      .addCase(fetchQuickViewDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchDashboardMetrics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardMetrics.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardMetrics = action.payload;
      })
      .addCase(fetchDashboardMetrics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dashboardSlice.reducer;
