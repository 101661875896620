// Routes.js
import pages from "../pages";
import {
  ACTIVE_CARE_PLANS_DETAILS_PAGE_PATH,
  ACTIVE_CARE_PLANS_PAGE_PATH,
  ADD_CAREPLAN_PAGE_PATH,
  ADD_PATIENT_PAGE_PATH,
  ADD_PROFILE_PAG_PAGE_PATH,
  ADD_SITE_PAGE_PATH,
  ADD_STAFF_BASE_PATH,
  ALERTS_PAGE_PATH,
  ANALYTICS_BHI_ASSESSMENT_REPORT_BASE_PATH,
  ANALYTICS_PDF_BHI_ASSESSMENT_REPORT_BASE_PATH,
  ASSESSMENT_PAGE_PATH,
  ASSESSMENT_QUESTIONNAIRES_PAGE_PATH,
  ASSESSMENT_TREND_REPORT_PDF_PATH,
  ASSIGN_CAREPLAN_PAGE_PATH,
  ASSIGN_MONITORING_STAFF_PATH,
  ASSIGN_STAFF_PAGE_PATH,
  BILLING_PAGE_PATH,
  BULK_ADD_PATIENT_PAGE_PATH,
  BULK_ASSIGN_MONITORING_STAFF_PATH,
  BULK_UPLOAD_PAGE_PATH,
  CAREPLAN_PAGE_PATH,
  CARE_PLAN_ASSIGNMENTS_BASE_PATH,
  CREATE_FORM_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  DIAGNOSIS_CODES_PAGE_PATH,
  DOCUMENTATION_PAGE_PATH,
  EDIT_ASSIGNED_CAREPLAN_ROUTE,
  EDIT_CAREPLAN_PAGE_PATH,
  EDIT_PATIENT_PAGE_PATH,
  EDIT_PROFILE_PAG_PAGE_PATH,
  EDIT_SITE_PAGE_PATH,
  EDIT_STAFF_BASE_PATH,
  EPISODES_DETAILS_BASE_PATH,
  EPISODES_FORM_ADD_PATH,
  EPISODES_FORM_EDIT_BASE_PATH,
  EPISODE_BILLING_PAGE_PATH,
  EVAL_FORMS_VIEW_BASE_PATH,
  FORMS_PAGE_PATH,
  FORMS_PREVIEW_PAGE_PATH,
  HELP_GUIDES_PAGE_PATH,
  INSURANCE_PAYERS_PAGE_PATH,
  INVOICE_FORM_VIEW_PATH,
  INVOICE_GENERATOR_PATH,
  INVOICE_OVER_VIEW_PATH,
  INVOICE_PREVIEW_VIEW_PATH,
  INVOICE_SENDER_VIEW_PATH,
  LOGIN_PAGE_PATH,
  NOTE_PAGE_PATH,
  NOTE_TEMPLATE_FORM_PAGE_PATH,
  NOTIFICATIONS_PAGE_PATH,
  PATIENTS_PAGE_PATH,
  PATIENT_ASSESSMENT_TREND_BASE_PATH,
  PATIENT_ASSIGN_STAFF_BASE_PATH,
  PATIENT_BULK_ADD_NOTES_PATH,
  PATIENT_BULK_TREND_REPORT_ASSESSMENT_PATH,
  PATIENT_BULK_TREND_REPORT_VITAL_PATH,
  PATIENT_VIEW_PAGE_PATH,
  PATIENT_VITAL_TREND_BASE_PATH,
  PHYSICIAN_BULK_UPLOAD_PATH,
  PRICES_PAGE_PATH,
  PROFILE_TAGS_PAGE_PATH,
  REPORTS_PAGE_PATH,
  SETTINGS_PAGE_PATH,
  SITES_PAGE_PATH,
  SMS_PAGE_PATH,
  STAFF_PAGE_PATH,
  STAFF_VIEW_PAGE_PATH,
  STAFF_VIEW_WIDGETS_PATH,
  UPDATE_PASSWORD_PAGE_PATH,
  VITAL_SIGNS_PAGE_PATH,
  VITAL_SIGNS_VIEW_PAGE_PATH,
  VITAL_TREND_REPORT_PDF_PATH,
} from "./PathConstants";
// Define the roles and their corresponding values
const ROLES = {
  PHYSICIAN: "PHYSICIAN",
  MONITORING_STAFF: "MONITORING_STAFF",
  ENROLLMENT_STAFF: "ENROLLMENT_STAFF",
};
const generateAddStaffRoute = (role) => ({
  element: <pages.addStaffPage />, // Replace with the actual component
  path: role ? `${ADD_STAFF_BASE_PATH}?role=${role}` : `${ADD_STAFF_BASE_PATH}`,
  isPrivate: true,
  isPublic: false,
  exact: true,
});
// Create a function to generate route objects for staff pages
const generateStaffPageRoute = (role) => ({
  element: <pages.staffPage />,
  path: role ? `${STAFF_PAGE_PATH}?role=${role}` : STAFF_PAGE_PATH,
  isPrivate: true,
  isPublic: false,
  exact: true,
});
const routes = [
  {
    element: <pages.login />,
    path: LOGIN_PAGE_PATH,
    isPrivate: false,
    isPublic: true,
    exact: true,
  },
  {
    element: <pages.resetPassword />,
    path: UPDATE_PASSWORD_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.dashboard />,
    path: DASHBOARD_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.StaffViewPage />,
    path: STAFF_VIEW_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.InvoiceGeneratorPage />,
    path: INVOICE_GENERATOR_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.InvoiceTablePage />,
    path: INVOICE_OVER_VIEW_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.InvoiceFormpage />,
    path: INVOICE_FORM_VIEW_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.InvoicePreviewPage />,
    path: INVOICE_PREVIEW_VIEW_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.InvoiceSenderpage />,
    path: INVOICE_SENDER_VIEW_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.assignMonitoringStaff />,
    path: ASSIGN_MONITORING_STAFF_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.physicianBulkUploadpage />,
    path: PHYSICIAN_BULK_UPLOAD_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.dashboardWidgetspage />,
    path: STAFF_VIEW_WIDGETS_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.patientViewPage />,
    path: PATIENT_VIEW_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.addPatientPage />, // Component for adding a patient
    path: ADD_PATIENT_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.bulkAddPatientPage />,
    path: BULK_ADD_PATIENT_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.addPatientPage />,
    path: EDIT_PATIENT_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.createEpisodePage />,
    path: EPISODES_FORM_ADD_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.patientAssignStaffPage />,
    path: PATIENT_ASSIGN_STAFF_BASE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.episodedetailsPage />,
    path: `${EPISODES_DETAILS_BASE_PATH}`,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.addCareplanPage />,
    path: EDIT_ASSIGNED_CAREPLAN_ROUTE,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.createEpisodePage />,
    path: `${EPISODES_FORM_EDIT_BASE_PATH}`,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.formEvalPage />,
    path: EVAL_FORMS_VIEW_BASE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.vitalTrendPage />,
    path: PATIENT_VITAL_TREND_BASE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.assessmentTrendPage />,
    path: PATIENT_ASSESSMENT_TREND_BASE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: false,
  },
  {
    element: <pages.assessmentTrendReportpdf />,
    path: ASSESSMENT_TREND_REPORT_PDF_PATH,
    isPrivate: true,
    isPublic: true,
    exact: false,
  },
  {
    element: <pages.VitalTrendReportpdf />,
    path: VITAL_TREND_REPORT_PDF_PATH,
    isPrivate: true,
    isPublic: true,
    exact: false,
  },
  // {
  //   element: <pages.assessmentReportPage />,
  //   path: PATIENT_ASSESSMENT_REPORT_BASE_PATH,
  //   isPrivate: true,
  //   isPublic: false,
  //   exact: true,
  // },
  {
    element: <pages.bhIAssessmentReportpage />,
    path: ANALYTICS_BHI_ASSESSMENT_REPORT_BASE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: false,
  },
  {
    element: <pages.BhiAssessmentPdfReport />,
    path: ANALYTICS_PDF_BHI_ASSESSMENT_REPORT_BASE_PATH,
    isPrivate: true,
    isPublic: true,
    exact: false,
  },
  {
    element: <pages.BulkAssignMonitoringStaffPage />,
    path: BULK_ASSIGN_MONITORING_STAFF_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.PatientBulkTrendReportVitalPage />,
    path: PATIENT_BULK_TREND_REPORT_VITAL_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.PatientBulkTrendReportAssessmentPage />,
    path: PATIENT_BULK_TREND_REPORT_ASSESSMENT_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },

  {
    element: <pages.PatientBulkAddNotesPage />,
    path: PATIENT_BULK_ADD_NOTES_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.carePlanAssignmentsPage />,
    path: CARE_PLAN_ASSIGNMENTS_BASE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.patientAssessmentpage />,
    path: ASSESSMENT_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.assesmentQuestionnairesPage />,
    path: ASSESSMENT_QUESTIONNAIRES_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.assignStaffPage />,
    path: ASSIGN_STAFF_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.addStaffPage />, // Replace with the actual component
    path: `${EDIT_STAFF_BASE_PATH}/:id?`,
    isPrivate: true,
    isPublic: false,
    exact: true,
    query: {
      role: undefined, // Optional query parameter
      staffName: undefined, // Optional query parameter
    },
  },
  // {
  //   element: <pages.staffPage />,
  //   path: STAFF_PAGE_PATH,
  //   isPrivate: true,
  //   isPublic: false,
  //   exact: true,
  // },
  ...Object.values(ROLES).map(generateStaffPageRoute),
  generateStaffPageRoute(), // General staff access
  // ...Object.values(ROLES).map(generateAddStaffRoute),
  generateAddStaffRoute(), // General staff access
  {
    element: <pages.patientsPage />,
    path: PATIENTS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: false,
  },
  {
    element: <pages.activeCareplanPage />,
    path: ACTIVE_CARE_PLANS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.activecarePlanDetailsPage />,
    path: ACTIVE_CARE_PLANS_DETAILS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.reportsPage />,
    path: REPORTS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.assignStaffPage />,
    path: BILLING_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.settingsPage />,
    path: SETTINGS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    // element: <pages.assignStaffPage />,
    element: <pages.sitesPage />,
    path: SITES_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.editSitePage />,
    path: EDIT_SITE_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.editSitePage />,
    path: ADD_SITE_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.vitalSignsPage />,
    path: VITAL_SIGNS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.vitalSignsViewPage />,
    path: VITAL_SIGNS_VIEW_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.formsPage />,
    path: FORMS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.formPreviewPage />,
    path: FORMS_PREVIEW_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.createFormPage />,
    path: CREATE_FORM_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.alertsPage />,
    path: ALERTS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.profileTagsPage />,
    path: PROFILE_TAGS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.addProfileTag />,
    path: ADD_PROFILE_PAG_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.addProfileTag />,
    path: EDIT_PROFILE_PAG_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.insurancePayersPage />,
    path: INSURANCE_PAYERS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.bulkUploadPage />,
    path: BULK_UPLOAD_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.diagnosisCodesPage />,
    path: DIAGNOSIS_CODES_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.episodeBillingPage />,
    path: EPISODE_BILLING_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.careplanPage />,
    path: CAREPLAN_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.addCareplanPage />,
    path: ADD_CAREPLAN_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.addCareplanPage />,
    path: EDIT_CAREPLAN_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.addCareplanPage />,
    path: ASSIGN_CAREPLAN_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.smsPage />,
    path: SMS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },

  {
    element: <pages.pricePage />,
    path: PRICES_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.notePage />,
    path: NOTE_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.noteTemplateFormPage />,
    path: NOTE_TEMPLATE_FORM_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.notificationsPage />,
    path: NOTIFICATIONS_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.assignStaffPage />,
    path: DOCUMENTATION_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  {
    element: <pages.helpGuides />,
    path: HELP_GUIDES_PAGE_PATH,
    isPrivate: true,
    isPublic: false,
    exact: true,
  },
  // Add other route objects for each menu item and submenu
];

export default routes;
