// trackEvent.js
import { getDecryptedData } from "./localStorage";
import logger from "./logtail"; // Import the BetterStack logger instance

const getDeviceInfo = () => {
  const { userAgent } = navigator;
  const isDesktop =
    /^((?!chrome|android).)*safari/i.test(userAgent) ||
    /^((?!firefox).)*mozilla/i.test(userAgent);
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  const deviceType = isDesktop ? "Desktop" : isMobile ? "Mobile" : "Unknown";
  const deviceModel = isMobile
    ? navigator.userAgentData?.mobile?.model
    : navigator.userAgentData?.platform;
  const deviceManufacturer = isMobile
    ? navigator.userAgentData?.mobile?.manufacturer
    : "";

  return {
    userAgent,
    deviceType,
    deviceModel,
    deviceManufacturer,
  };
};

const getBrowserInfo = () => {
  const { userAgent } = navigator;
  let browserName = "Unknown";
  let browserVersion = "Unknown";

  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Firefox";
    browserVersion = userAgent.split("Firefox/")[1];
  } else if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Chrome";
    browserVersion = userAgent.split("Chrome/")[1];
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Safari";
    browserVersion = userAgent.split("Version/")[1];
  } else if (userAgent.indexOf("Opera") > -1) {
    browserName = "Opera";
    browserVersion = userAgent.split("OPR/")[1];
  } else if (userAgent.indexOf("Trident") > -1) {
    browserName = "Internet Explorer";
    browserVersion = userAgent.split("rv:")[1];
  }

  return {
    browserName,
    browserVersion,
  };
};

const getOsInfo = () => {
  const { userAgent } = navigator;
  let osName = "Unknown";
  let osVersion = "Unknown";

  if (navigator.appVersion.indexOf("Win") !== -1) {
    osName = "Windows";
    osVersion = userAgent.split("Windows NT ")[1].split(";")[0];
  } else if (userAgent.indexOf("Linux") > -1) {
    osName = "Linux";
    osVersion = userAgent.split("Linux ")[1];
  } else if (userAgent.indexOf("Mac") > -1) {
    osName = "macOS";
    osVersion = userAgent.split("Mac OS X ")[1].split(")")[0];
  } else if (userAgent.indexOf("Android") > -1) {
    osName = "Android";
    osVersion = userAgent.split("Android ")[1].split(";")[0];
  } else if (userAgent.indexOf("iPhone") > -1) {
    osName = "iOS";
    osVersion = userAgent.split("iPhone OS ")[1].split(" ")[0];
  }

  return {
    osName,
    osVersion,
  };
};

const trackEvent = (eventData) => {
  const {
    user_id,
    trace_id = getDecryptedData("trace_id"), // Generate UUID if trace_id is not provided
    event,
    subEvent,
    data,
    type,
    description,
    trackDetails,
    timestamp = new Date().toISOString(),
  } = eventData;

  const deviceInfo = getDeviceInfo();
  const browserInfo = getBrowserInfo();
  const osInfo = getOsInfo();

  navigator.geolocation.getCurrentPosition(
    (position) => {
      const { latitude, longitude } = position.coords;

      const screen = {
        width: window.screen.width,
        height: window.screen.height,
        colorDepth: window.screen.colorDepth,
        pixelRatio: window.devicePixelRatio,
      };

      const meta = {
        location: { latitude, longitude },
        device: deviceInfo,
        browser: browserInfo,
        os: osInfo,
        screen,
        userLanguage: navigator.language,
        userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      const enrichedEventData = {
        user_id,
        trace_id,
        event,
        subEvent,
        data,
        type,
        description,
        timestamp,
        trackDetails,
        meta,
      };

      logger.info("Event Tracked", enrichedEventData);
    },
    (error) => {
      logger.error("Error getting location", error);

      // const meta = {
      //   location: {},
      //   device: deviceInfo,
      //   browser: browserInfo,
      //   os: osInfo,
      //   screen: {
      //     width: window.screen.width,
      //     height: window.screen.height,
      //     colorDepth: window.screen.colorDepth,
      //     pixelRatio: window.devicePixelRatio,
      //   },
      //   userLanguage: navigator.language,
      //   userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      //   currentLocationUri: window.location.href,
      // };

      // const enrichedEventData = {
      //   user_id,
      //   trace_id,
      //   event,
      //   subEvent,
      //   data,
      //   type,
      //   description,
      //   timestamp,
      //   meta,
      //   trackDetails,
      // };

      // logger.info("Event Tracked", enrichedEventData);
    }
  );
};

const trackEventerror = (eventData) => {
  const {
    user_id,
    trace_id = getDecryptedData("trace_id"), // Generate UUID if trace_id is not provided
    event,
    subEvent,
    data,
    type,
    description,
    trackDetails,
    timestamp = new Date().toISOString(),
  } = eventData;

  const deviceInfo = getDeviceInfo();
  const browserInfo = getBrowserInfo();
  const osInfo = getOsInfo();

  navigator.geolocation.getCurrentPosition(
    (position) => {
      const { latitude, longitude } = position.coords;

      const screen = {
        width: window.screen.width,
        height: window.screen.height,
        colorDepth: window.screen.colorDepth,
        pixelRatio: window.devicePixelRatio,
      };

      const meta = {
        location: { latitude, longitude },
        device: deviceInfo,
        browser: browserInfo,
        os: osInfo,
        screen,
        userLanguage: navigator.language,
        userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      const enrichedEventData = {
        user_id,
        trace_id,
        event,
        subEvent,
        data,
        type,
        description,
        timestamp,
        trackDetails,
        meta,
      };

      logger.error("Event Tracked", enrichedEventData);
    },
    (error) => {
      logger.error("Error getting location", error);

      // const meta = {
      //   location: {},
      //   device: deviceInfo,
      //   browser: browserInfo,
      //   os: osInfo,
      //   screen: {
      //     width: window.screen.width,
      //     height: window.screen.height,
      //     colorDepth: window.screen.colorDepth,
      //     pixelRatio: window.devicePixelRatio,
      //   },
      //   userLanguage: navigator.language,
      //   userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      //   currentLocationUri: window.location.href,
      // };

      // const enrichedEventData = {
      //   user_id,
      //   trace_id,
      //   event,
      //   subEvent,
      //   data,
      //   type,
      //   description,
      //   timestamp,
      //   meta,
      //   trackDetails,
      // };

      // logger.info("Event Tracked", enrichedEventData);
    }
  );
};

const eventTrackers = {
  trackEvent,
  trackEventerror,
};
export default eventTrackers;
