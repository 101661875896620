import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const settingService = {
  getSettings: (is_active) =>
    Axiosinstance.get(API_URL_CONSTANTS.SETTINGS_API_URL, {
      params: { is_active },
    }),
  updateSettings: (id, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.UPDATE_SETTINGS_API_URL}/${id}`,
      data
    ),
};
export { settingService };
