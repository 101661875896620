import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { settingService } from "../../services/settings";
import { setLoadingFalse, setLoadingTrue } from "../SpinSlice/SpinSlice"; // Import loading state actions

export const fetchSettings = createAsyncThunk(
  "settings/fetchSettings",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue()); // Set loading state to true
    try {
      const response = await settingService.getSettings();
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false
      return response.data; // Return the settings data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false in case of error
      return thunkAPI.rejectWithValue(error.response.data); // Reject with value to trigger the 'rejected' action
    }
  }
);
export const updateSettings = createAsyncThunk(
  "settings/updateSettings",
  async ({ id, data }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue()); // Set loading state to true
    try {
      const response = await settingService.updateSettings(id, data);
      thunkAPI.dispatch(fetchSettings());
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false
      return response.data; // Return the updated settings data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false in case of error
      return thunkAPI.rejectWithValue(error.response.data); // Reject with value to trigger the 'rejected' action
    }
  }
);

// Import the fetchSettings thunk

const initialState = {
  settingsData: null,
  loading: false,
  error: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settingsData = action.payload;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default settingsSlice.reducer;
