import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { admin } from "../../services/Admin";
import CustomNotification from "../../components/common/CustomNotification";
import { site } from "../../services/sites";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

export const fecthsiteList = createAsyncThunk(
  "fetch/siteList",
  async (params, thunkAPI) => {
    const { limit, offset, search_text } = params;
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await site.getsiteList(limit, offset, search_text);
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const CreatesiteList = createAsyncThunk(
  "fetch/siteList",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await site.createSite(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        // message.success(response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const Updatesiteinfo = createAsyncThunk(
  "fetch/Updatesiteinfo",
  async ({ SiteId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await site.updateSiteInfo(SiteId, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const fetchsiteinfo = createAsyncThunk(
  "fetch/fetchsiteinfo",
  async ({ itemId, handlerouter }, thunkAPI) => {
    //
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await site.getsiteinfo(itemId);

      if (response?.data.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const Removesiteinfo = createAsyncThunk(
  "fetch/Removesiteinfo",
  async ({ currentsite, fetchData }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await site.deleteSiteInfo(currentsite);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        fetchData();
        CustomNotification("success", "Success", response.data.status);

        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
const siteSlice = createSlice({
  name: "site",
  initialState: {
    siteList: [],
    siteinfo: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fecthsiteList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthsiteList.fulfilled, (state, action) => {
        state.loading = false;
        state.siteList = action.payload;
      })
      .addCase(fecthsiteList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchsiteinfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchsiteinfo.fulfilled, (state, action) => {
        state.loading = false;
        state.siteinfo = action.payload;
      })
      .addCase(fetchsiteinfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default siteSlice.reducer;
