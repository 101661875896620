import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { admin } from "../../services/Admin";
import CustomNotification from "../../components/common/CustomNotification";
import { patient } from "../../services/patient";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

export const fecthpatientList = createAsyncThunk(
  "fetch/patientList",
  async (
    {
      limit,
      offset,
      search_text,
      sort_by,
      order,
      episode_type_id,
      staff,
      physician,
      site_id,
      patient_status,
      episode_status,
      no_active_episode,
      rpm_likely_to_record_vital,
      rpm_not_recorded_in_last_three,
      rpm_not_recorded_this_month,
      rpm_completed_this_month,
      rpm_patient_consent,
      bhi_patient_consent,
      physician_consent,
      device_pending_setup_status,
      device_setup_completed_but_vitals,
      pending_tms,
      completed_tms,
      pending_cmt,
      completed_cmt,
      bhi_completed_assessment,
      bhi_pending_assessment,
      apply_careplan_filter,
      device_setup_completed_this_month,
      is_vitals_recorded,
    },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getpatientList({
        limit,
        offset,
        search_text,
        sort_by,
        order,
        episode_type_id,
        staff,
        physician,
        site_id,
        patient_status,
        episode_status,
        no_active_episode,
        rpm_likely_to_record_vital,
        rpm_not_recorded_in_last_three,
        rpm_not_recorded_this_month,
        rpm_completed_this_month,
        rpm_patient_consent,
        bhi_patient_consent,
        physician_consent,
        device_pending_setup_status,
        device_setup_completed_but_vitals,
        pending_tms,
        completed_tms,
        pending_cmt,
        completed_cmt,
        bhi_completed_assessment,
        bhi_pending_assessment,
        apply_careplan_filter,
        device_setup_completed_this_month,
        is_vitals_recorded,
      });
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const fecthAssessmentpatientList = createAsyncThunk(
  "fetch/AssessmentpatientList",
  async (params, thunkAPI) => {
    const {
      search_text,
      sort_by,
      order,
      episode_type_id,
      staff,
      physician,
      site_id,
      patient_status,
      episode_status,
      no_active_episode,
    } = params;
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getpatientList({
        // limit,
        // offset,
        search_text,
        sort_by,
        order,
        episode_type_id,
        staff,
        physician,
        site_id,
        patient_status,
        episode_status,
        no_active_episode,
      });
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const fecthEpisodeList = createAsyncThunk(
  "Episode/fecthEpisodeList",
  async (params, thunkAPI) => {
    const { limit, offset, id } = params;

    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getEpisodeList(limit, offset, id);

      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const CreatepatientList = createAsyncThunk(
  "Create/patientList",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.createpatient(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        // message.success(response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const CreateEpisodeList = createAsyncThunk(
  "Create/CreateEpisodeList",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.createEpisode(data);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate(response?.data);
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const UpdateEpisodeinfo = createAsyncThunk(
  "Update/UpdateEpisodeinfo",
  async ({ EpisodeId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.updateEpisodeInfo(EpisodeId, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const UpdateEpisodeSuspendinfo = createAsyncThunk(
  "Update/UpdateEpisodesuspendinfo",
  async ({ patientId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.suspendPatientEpisode(patientId, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const fetchEpisodeinfo = createAsyncThunk(
  "Episodeinfo/fetchEpisodeinfo",
  async ({ itemId, handlerouter }, thunkAPI) => {
    //
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getEpisodeInfo(itemId);

      if (response?.data?.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const UpdateEpisodeCareplan = createAsyncThunk(
  "Update/UpdateEpisodeCareplan",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.updateEpisodeCarePlanInfo(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const fetchEpisodeCareplanList = createAsyncThunk(
  "Episode/fetchEpisodeCareplanList",
  async ({ itemId, handlerouter }, thunkAPI) => {
    //
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getEpisodeCarePlanlist(itemId);

      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const Updatepatientinfo = createAsyncThunk(
  "fetch/Updatepatientinfo",
  async ({ patientId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.updatepatientInfo(patientId, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const getPatientEpisodeCarePlan = createAsyncThunk(
  "patientEpisode/EpisodeCarePlan",
  async ({ episodeId, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getPatientEpisodeCarePlan(episodeId);

      if (response?.data.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
// getEpisodeInterventionFormCompleted
export const getEpisodeInterventionFormCompleted = createAsyncThunk(
  "patientEpisode/getEpisodeInterventionFormCompleted",
  async ({ episodeInterventionId, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getEpisodeInterventionFormCompleted(
        episodeInterventionId
      );

      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        // CustomNotification('success', 'Success', response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const updatePatientEpisodeCarePlan = createAsyncThunk(
  "patientEpisode/updateCarePlan",
  async ({ episodeId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.updatePatientEpisodeCarePlan(
        episodeId,
        data
      );

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// getEpisodeLogList

export const getEpisodeLogList = createAsyncThunk(
  "patientEpisode/getEpisodeLogList",
  async ({ limit, offset, id, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getEpisodeLogList(limit, offset, id);

      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        if (handlenavigate) {
          handlenavigate();
        }
        // CustomNotification('success', 'Success', response.data.status);
        return response?.data.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
//getEpisodeLogActivityList
export const getEpisodeLogActivityList = createAsyncThunk(
  "patientEpisode/getEpisodeLogActivityList",
  async ({ limit, offset, id }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getEpisodeLogActivityList(
        limit,
        offset,
        id
      );

      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        // handlenavigate();
        // CustomNotification('success', 'Success', response.data.status);
        return response?.data.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

// createEpisodeLog
export const createEpisodeLog = createAsyncThunk(
  "patientEpisode/createEpisodeLog",
  async ({ data, handlenavigate, setIsButtonLoading }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.createEpisodeLog(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        setIsButtonLoading();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      setIsButtonLoading();
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
// updateEpisodeLog
export const updateEpisodeLog = createAsyncThunk(
  "patientEpisode/updateEpisodeLog",
  async ({ id, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.updateEpisodeLog(id, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// updateEpisodeLog
export const dischargepatientInfo = createAsyncThunk(
  "patient/dischargepatientInfo",
  async ({ id, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.dischargepatientInfo(id, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const markPatientNotOnboarded = createAsyncThunk(
  "patient/markPatientNotOnboarded",
  async ({ id, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.markPatientNotOnboarded(id, data);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const fetchpatientinfo = createAsyncThunk(
  "fetch/fetchpatientinfo",
  async ({ itemId, handlerouter }, thunkAPI) => {
    //
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getpatientinfo(itemId);

      if (response?.data?.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const Removepatientinfo = createAsyncThunk(
  "fetch/Removepatientinfo",
  async ({ currentpatient, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingTrue());
    try {
      const response = await patient.deletepatientInfo(currentpatient);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setButtonLoadingTrue());
    thunkAPI.dispatch(setLoadingFalse());
  }
);
//
export const fetchAllergies = createAsyncThunk(
  "fetch/allergies",
  async (params, thunkAPI) => {
    const { id } = params;
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.getpatientAllergies(id);

      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        return response?.data?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const fetchContacts = createAsyncThunk(
  "fetch/contacts",
  async (params, thunkAPI) => {
    const { id } = params;
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getpatientContacts(id);
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const fetchGuardians = createAsyncThunk(
  "fetch/guardians",
  async (params, thunkAPI) => {
    const { id } = params;
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getpatientGuardians(id);
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setButtonLoadingTrue());
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const fetchDevices = createAsyncThunk(
  "fetch/Devices",
  async (params, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    const { limit, offset, id } = params;
    try {
      const response = await patient.getpatientDevices(limit, offset, id);

      if (response.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
// Update Contacts
export const createContacts = createAsyncThunk(
  "create/contacts",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.createContact(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setButtonLoadingTrue());
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// Fetch Contacts
export const fetchContact = createAsyncThunk(
  "fetch/contactinfo",
  async ({ patientId, handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getContactInfo(patientId);

      if (response?.data?.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const UpdateContactinfo = createAsyncThunk(
  "update/UpdateContactinfo",
  async ({ patientId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.updateContactInfo(patientId, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
// Remove Contacts
export const removeContacts = createAsyncThunk(
  "remove/contacts",
  async ({ currentContact, fetchData }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.deleteContactInfo(currentContact);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        fetchData();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// Update Guardians
export const createGuardians = createAsyncThunk(
  "create/guardians",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.createGuardian(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// Fetch Guardians
export const fetchGuardian = createAsyncThunk(
  "fetch/guardian",
  async ({ patientId, handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getpatientGuardians(patientId);

      if (response?.data?.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const UpdateGuardianinfo = createAsyncThunk(
  "update/UpdateGuardianinfo",
  async ({ patientId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.updateGuardianInfo(patientId, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
// Remove Guardians
export const removeGuardians = createAsyncThunk(
  "remove/guardians",
  async ({ currentGuardian, fetchData }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.deleteGuardianInfo(currentGuardian);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        fetchData();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// Update Devices
export const createDevices = createAsyncThunk(
  "create/devices",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.createDevice(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// Fetch Devices
export const fetchDevice = createAsyncThunk(
  "fetch/devices",
  async ({ patientId, handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await patient.getpatientDevices(patientId);

      if (response?.data?.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setButtonLoadingTrue());
    thunkAPI.dispatch(setLoadingFalse());
  }
);
// getPatientAssessment
export const fetchPatientAssessment = createAsyncThunk(
  "fetch/patientAssessment",
  async ({ patientId, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      if (patientId) {
        const response = await patient.getPatientAssessment(patientId);

        if (response?.data?.id) {
          thunkAPI.dispatch(setLoadingFalse());
          thunkAPI.dispatch(setButtonLoadingTrue());
          if (handlenavigate) {
            handlenavigate();
          }

          return response?.data;
        }
      } else {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return [];
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
// getPatientFormsList
export const fetchPatientFormsList = createAsyncThunk(
  "fetch/patientFormsList",
  async ({ patientId }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      if (patientId) {
        const response = await patient.getPatientFormsList(patientId);

        if (response?.data) {
          thunkAPI.dispatch(setLoadingFalse());
          thunkAPI.dispatch(setButtonLoadingTrue());
          // handlerouter();
          return response?.data;
        }
      } else {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return [];
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      // return error;
    }

    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const fetchPatientTrendAssessment = createAsyncThunk(
  "fetch/PatientTrendAssessment",
  async (
    { patientId, handlerouter, start_date, end_date, form_id },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      if (patientId) {
        const response = await patient.getAssessmentTrendData(
          patientId,
          start_date,
          end_date,
          form_id
        );

        if (response?.data) {
          thunkAPI.dispatch(setLoadingFalse());
          thunkAPI.dispatch(setButtonLoadingTrue());
          handlerouter();
          return response?.data;
        }
      } else {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return [];
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

// Remove Devices
export const removeDevices = createAsyncThunk(
  "remove/devices",
  async ({ currentDevice, fetchData }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.deleteDeviceInfo(currentDevice);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        fetchData();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
// Update Allergies
export const createAllergies = createAsyncThunk(
  "create/allergies",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.createAllergy(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const fetchDeviceSetupTypesAll = createAsyncThunk(
  "deviceSetupTypes/fetchAll",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.getDeviceSetupTypesList();
      thunkAPI.dispatch(setLoadingFalse());
      return response.data; // Return the response data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
  }
);
export const AssignDeviceSetup = createAsyncThunk(
  "deviceSetup/post",
  async ({ data, handlenavigatedevice }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.CreateDeviceSetupTypesList(data);
      thunkAPI.dispatch(setLoadingFalse());
      handlenavigatedevice();
      CustomNotification(
        "success",
        "Success",
        "Device setup data posted successfully"
      );
      return response.data; // Return the response data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
  }
);

export const fetchAllDeviceSetupTasks = createAsyncThunk(
  "deviceSetupTasks/fetchAll",
  async (episodeId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.getDeviceSetupTasks(episodeId);
      thunkAPI.dispatch(setLoadingFalse());
      return response.data; // Return the response data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
  }
);

export const fetchAllDeviceTypes = createAsyncThunk(
  "fetchAllDeviceTypes/fetchAll",
  async (episodeId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.getDeviceTypesList();
      thunkAPI.dispatch(setLoadingFalse());
      return response.data; // Return the response data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
  }
);

export const updateEpisodeDeviceSetupTask = createAsyncThunk(
  "patient/updateEpisodeDeviceSetupTask",
  async ({ id, data, handleNavigateDevice }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue()); // Set loading state to true
    try {
      const response = await patient.updateDeviceSetupTask(id, data);
      thunkAPI.dispatch(setLoadingFalse());
      handleNavigateDevice(); // Set loading state to false
      return response.data; // Return the updated device setup task data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false in case of error
      return thunkAPI.rejectWithValue(error.response.data); // Reject with value to trigger the 'rejected' action
    }
  }
);

// Fetch Allergies
export const fetchAllergie = createAsyncThunk(
  "fetch/allergiesinfo",
  async ({ patientId, handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingTrue());
    try {
      const response = await patient.getpatientAllergies(patientId);

      if (response?.data?.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setButtonLoadingTrue());
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const UpdateAllergiesinfo = createAsyncThunk(
  "update/UpdateAllergiesinfo",
  async ({ patientId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.updateAllergyInfo(patientId, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
// Remove Allergies
export const removeAllergies = createAsyncThunk(
  "remove/allergiesinfo",
  async ({ currentAllergy, fetchData }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.deleteAllergyInfo(currentAllergy);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        fetchData();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const fetchPatientVitalTrend = createAsyncThunk(
  "patient/fetchPatientVitalTrend",
  async (
    { patientId, vital_sign_id, start_date, end_date, handlerouter },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setLoadingTrue()); // Set loading state to true
    try {
      const response = await patient.getpatientVitalinfo(
        patientId,
        vital_sign_id,
        start_date,
        end_date
      );
      handlerouter();
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false
      return response.data; // Return the fetched patient vital trend data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false in case of error
      return thunkAPI.rejectWithValue(error.response.data); // Reject with value to trigger the 'rejected' action
    }
  }
);

export const checkPatient = createAsyncThunk(
  "patient/checkPatient",
  async ({ firstname, lastname, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await patient.checkPatient(firstname, lastname);
      handlenavigate();
      thunkAPI.dispatch(setLoadingFalse());
      // CustomNotification("success", "Success", "Patient check successful");
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // // CustomNotification("error", "Error", "Failed to check patient");
      // return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  patientList: [],
  EpisodeList: [],
  FromsList: [],
  patientInfo: {},
  allergies: [],
  contacts: [],
  contactInfo: {},
  allergieInfo: {},
  episodeDetails: {},
  guardianInfo: {},
  deviceInfo: {},
  EpisodeLogList: [],
  EpisodeLogActivityList: [],
  InterventionForminfo: {},
  guardians: [],
  devices: [],
  EpisodeCareplanList: [],
  EpisodeCareplaninfo: [],
  patientAssessment: [],
  patientTrendAssessment: [],
  patientVitalTrendData: [],
  loading: false,
  error: null,
  AssessmentpatientList: [],
  Devicetypes: [],
  Devicetasks: [],
  allDeviceTypes: [],
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    resetDataState(state, action) {
      // Reset only the specified part of the state based on action.payload
      state[action.payload] = initialState[action.payload];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fecthpatientList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthpatientList.fulfilled, (state, action) => {
        state.loading = false;
        state.patientList = action.payload;
      })
      .addCase(fecthpatientList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fecthAssessmentpatientList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthAssessmentpatientList.fulfilled, (state, action) => {
        state.loading = false;
        state.AssessmentpatientList = action.payload;
      })
      .addCase(fecthAssessmentpatientList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPatientVitalTrend.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPatientVitalTrend.fulfilled, (state, action) => {
        state.loading = false;
        state.patientVitalTrendData = action.payload;
      })
      .addCase(fetchPatientVitalTrend.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fecthEpisodeList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthEpisodeList.fulfilled, (state, action) => {
        state.loading = false;
        state.EpisodeList = action.payload;
      })
      .addCase(fecthEpisodeList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEpisodeLogList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEpisodeLogList.fulfilled, (state, action) => {
        state.loading = false;
        state.EpisodeLogList = action.payload;
      })
      .addCase(getEpisodeLogList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEpisodeLogActivityList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEpisodeLogActivityList.fulfilled, (state, action) => {
        state.loading = false;
        state.EpisodeLogActivityList = action.payload;
      })
      .addCase(getEpisodeLogActivityList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEpisodeCareplanList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEpisodeCareplanList.fulfilled, (state, action) => {
        state.loading = false;
        state.EpisodeCareplanList = action.payload;
      })
      .addCase(fetchEpisodeCareplanList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPatientEpisodeCarePlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPatientEpisodeCarePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.EpisodeCareplaninfo = action.payload;
      })
      .addCase(getPatientEpisodeCarePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEpisodeInterventionFormCompleted.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getEpisodeInterventionFormCompleted.fulfilled,
        (state, action) => {
          state.loading = false;
          state.InterventionForminfo = action.payload;
        }
      )
      .addCase(
        getEpisodeInterventionFormCompleted.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(fetchpatientinfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchpatientinfo.fulfilled, (state, action) => {
        state.loading = false;
        state.patientInfo = action.payload;
      })
      .addCase(fetchpatientinfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEpisodeinfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEpisodeinfo.fulfilled, (state, action) => {
        state.loading = false;
        state.episodeDetails = action.payload;
      })
      .addCase(fetchEpisodeinfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPatientAssessment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPatientAssessment.fulfilled, (state, action) => {
        state.loading = false;
        state.patientAssessment = action.payload;
      })
      .addCase(fetchPatientAssessment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPatientTrendAssessment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPatientTrendAssessment.fulfilled, (state, action) => {
        state.loading = false;
        state.patientTrendAssessment = action.payload;
      })
      .addCase(fetchPatientTrendAssessment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPatientFormsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPatientFormsList.fulfilled, (state, action) => {
        state.loading = false;
        state.FromsList = action.payload;
      })
      .addCase(fetchPatientFormsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllergies.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllergies.fulfilled, (state, action) => {
        state.loading = false;
        state.allergies = action.payload;
      })
      .addCase(fetchAllergies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllergie.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllergie.fulfilled, (state, action) => {
        state.loading = false;
        state.allergieInfo = action.payload;
      })
      .addCase(fetchAllergie.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchContacts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.loading = false;
        state.contacts = action.payload;
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchContact.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        state.loading = false;
        state.contactInfo = action.payload;
      })
      .addCase(fetchContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchGuardians.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGuardians.fulfilled, (state, action) => {
        state.loading = false;
        state.guardians = action.payload;
      })
      .addCase(fetchGuardians.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchGuardian.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGuardian.fulfilled, (state, action) => {
        state.loading = false;
        state.guardianInfo = action.payload;
      })
      .addCase(fetchGuardian.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchDevices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDevices.fulfilled, (state, action) => {
        state.loading = false;
        state.devices = action.payload;
      })
      .addCase(fetchDevices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllDeviceTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllDeviceTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.allDeviceTypes = action.payload;
      })
      .addCase(fetchAllDeviceTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchDevice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDevice.fulfilled, (state, action) => {
        state.loading = false;
        state.deviceInfo = action.payload;
      })
      .addCase(fetchDevice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchDeviceSetupTypesAll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDeviceSetupTypesAll.fulfilled, (state, action) => {
        state.loading = false;
        state.Devicetypes = action.payload;
      })
      .addCase(fetchDeviceSetupTypesAll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllDeviceSetupTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllDeviceSetupTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.Devicetasks = action.payload;
      })
      .addCase(fetchAllDeviceSetupTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetDataState } = patientSlice.actions;
export default patientSlice.reducer;
