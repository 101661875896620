import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomNotification from "../../components/common/CustomNotification";
import { episodeTms } from "../../services/episodeTms"; // Adjust the path as necessary
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

// Async thunk to get episode TMS logs list
export const fetchEpisodeTmsActivities = createAsyncThunk(
  "tms/fetchEpisodeTmsAcitivities",
  async ({ _ }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());

    try {
      const response = await episodeTms.getepisodeTmslogsAcitivityList();
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return response.data; // Return the response data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
  }
);

// Async thunk to create an episode TMS log
export const createEpisodeTmsLog = createAsyncThunk(
  "tms/createEpisodeTmsLog",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await episodeTms.createEpisodeTms(data);
      handlenavigate();
      thunkAPI.dispatch(setLoadingFalse());

      CustomNotification(
        "success",
        "Success",
        "Episode TMS log created successfully"
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Async thunk to get episode TMS info by ID
export const fetchEpisodeTmsInfo = createAsyncThunk(
  "tms/fetchEpisodeTmsInfo",
  async ({ id, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await episodeTms.getepisodeTmsinfo(id);

      if (handlenavigate) {
        handlenavigate();
      }
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Async thunk to delete episode TMS info by ID
export const deleteEpisodeTmsInfo = createAsyncThunk(
  "tms/deleteEpisodeTmsInfo",
  async ({ id }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await episodeTms.deleteEpisodeTmsInfo(id);
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification(
        "success",
        "Success",
        "Episode TMS info deleted successfully"
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Async thunk to update episode TMS info by ID
export const updateEpisodeTmsInfo = createAsyncThunk(
  "tms/updateEpisodeTmsInfo",
  async ({ id, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await episodeTms.updateEpisodeTmsInfo(id, data);
      thunkAPI.dispatch(setLoadingFalse());
      handlenavigate();
      CustomNotification(
        "success",
        "Success",
        "Episode TMS info updated successfully"
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Redux slice
const episodetmsSlice = createSlice({
  name: "tms",
  initialState: {
    tmsLogs: [],
    tmsActivities: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEpisodeTmsActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEpisodeTmsActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.tmsActivities = action.payload;
      })
      .addCase(fetchEpisodeTmsActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createEpisodeTmsLog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createEpisodeTmsLog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchEpisodeTmsInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEpisodeTmsInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.tmsLogs = action.payload;
      })
      .addCase(fetchEpisodeTmsInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteEpisodeTmsInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteEpisodeTmsInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateEpisodeTmsInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEpisodeTmsInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default episodetmsSlice.reducer;
