import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Container from "../../components/common/Container/Container";

import { useAdvancedAnalytics } from "../../hooks/AdvancedAnalyticsService";
import { LOGIN_PAGE_PATH } from "../PathConstants";

const PrivateRoute = (props) => {
  const userinfo = useSelector((state) => state.auth?.profileinfo);
  const auth = userinfo?.authorization ? true : false;
  const { trackEvent } = useAdvancedAnalytics();

  useEffect(() => {
    trackEvent("Private Route Access", { authorized: auth });
  }, [auth, trackEvent]);

  return (
    <>
      {auth ? (
        <Container>{props.children}</Container>
      ) : (
        <Navigate to={LOGIN_PAGE_PATH} />
      )}
    </>
  );
};

export default PrivateRoute;
