import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomNotification from "../../components/common/CustomNotification";
import { staff } from "../../services/staff";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

export const fecthstaffList = createAsyncThunk(
  "fetch/staffList",
  async (params, thunkAPI) => {
    const { role, navigate, limit, offset, search_text, status } = params;
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await staff.getstaffList(
        role,
        limit,
        offset,
        search_text,
        status
      );
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        if (navigate) {
          navigate();
        }
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
  }
);

export const fecthMonitorstaffList = createAsyncThunk(
  "fetch/MonitorstaffList",
  async (params, thunkAPI) => {
    const { role, navigate, limit, offset, search_text, status } = params;
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await staff.getstaffList(
        role,
        limit,
        offset,
        search_text,
        status
      );
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        if (navigate) {
          navigate();
        }
        return response?.data.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
  }
);
export const fecthRoleWisePrivileges = createAsyncThunk(
  "fetch/Rolewiseprivileges",
  async (userId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await staff.getRolewiseprivileges(userId);
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const fecthStaffProfile = createAsyncThunk(
  "fetch/fecthStaffProfile",
  async ({ userId, navigate, role, staffName, val }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await staff.getstaffProfile(userId);

      if (response?.status) {
        // Assuming you're using React Router for navigation
        const url = `/staff/${val}/${userId}?role=${role}&staffName=${encodeURIComponent(
          staffName
        )}`;
        navigate(url);
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const fecthStaffProfile1 = createAsyncThunk(
  "fetch/fecthStaffProfile",
  async (userId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await staff.getstaffProfile(userId);

      if (response?.status) {
        // Assuming you're using React Router for navigation

        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const fecthPrivileges = createAsyncThunk(
  "fetch/fecthPrivileges",
  async (userId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await staff.getprivilegesList(userId);
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const fecthRoleList = createAsyncThunk(
  "fetch/fecthRoleList",
  async (userId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await staff.getRoleList();
      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const CreateStaffMembers = createAsyncThunk(
  "Create/CreateStaffMembers",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await staff.createStaff(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const AssignStaffMembers = createAsyncThunk(
  "Create/AssignStaffMembers",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await staff.assignStaff(data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const UpdateStaffMembers = createAsyncThunk(
  "Create/UpdateStaffMembers",
  async ({ id, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await staff.updateStaffInfo(id, data);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const removeStaffMemeber = createAsyncThunk(
  "Delete/removeStaffMemeber",
  async ({ id, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await staff.removeStaffMemeber(id);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", response.data.status, "");
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const checkStaffUser = createAsyncThunk(
  "staff/checkStaffUser",
  async ({ email, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await staff.checkStaffUser(email);
      handlenavigate();
      thunkAPI.dispatch(setLoadingFalse());
      // CustomNotification("success", "Success", "Staff user check successful");
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // CustomNotification("error", "Error", "Failed to check staff user");
      // return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  staffList: [],
  MonitorstaffList: [],
  RolePrivileges: [],
  Privileges: [],
  Roles: [],
  staffProfile: {},
  loading: false,
  error: null,
};
const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    resetStaffDataState(state, action) {
      // Reset only the specified part of the state based on action.payload

      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fecthstaffList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthstaffList.fulfilled, (state, action) => {
        state.loading = false;
        state.staffList = action.payload;
      })
      .addCase(fecthstaffList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fecthMonitorstaffList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthMonitorstaffList.fulfilled, (state, action) => {
        state.loading = false;
        state.MonitorstaffList = action.payload;
      })
      .addCase(fecthMonitorstaffList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fecthRoleWisePrivileges.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthRoleWisePrivileges.fulfilled, (state, action) => {
        state.loading = false;
        state.RolePrivileges = action.payload;
      })
      .addCase(fecthRoleWisePrivileges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fecthRoleList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthRoleList.fulfilled, (state, action) => {
        state.loading = false;
        state.Roles = action.payload;
      })
      .addCase(fecthRoleList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fecthStaffProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthStaffProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.staffProfile = action.payload;
      })
      .addCase(fecthStaffProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fecthPrivileges.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthPrivileges.fulfilled, (state, action) => {
        state.loading = false;
        state.Privileges = action.payload;
      })
      .addCase(fecthPrivileges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { resetStaffDataState } = staffSlice.actions;

export default staffSlice.reducer;
