import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const report = {
  // Organizational form-related functions

  getCmtreport: (physician_id, month, year) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.REPORT_CMT_COMPLETED_API_URL}`, {
      params: {
        physician_id,
        month,
        year,
      },
    }),

  shareReport: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.REPORT_SHARE_API_URL, data),
  getDevicereport: (physician_id, month, year) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.DEVICE_SETUP_REPORT_API_URL}`, {
      params: {
        physician_id,
        month,
        year,
      },
    }),
  getTimeTmsreport: (physician_id, month, year) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.TMS_TIME_REPORT_API_URL}`, {
      params: {
        physician_id,
        month,
        year,
      },
    }),
  getTimeTms_A_report: (physician_id, month, year) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.TMS_TIME_REPORT_A_API_URL}`, {
      params: {
        physician_id,
        month,
        year,
      },
    }),
  getTimeTms_B_report: (physician_id, month, year) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.TMS_TIME_REPORT_B_API_URL}`, {
      params: {
        physician_id,
        month,
        year,
      },
    }),
  getPatientsDurationreport: (physician_id, month, year) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.PATIENT_REPORTS_API_URL}`, {
      params: {
        physician_id,
        month,
        year,
      },
    }),
  getPatientsOnboardedreport: (physician_id, month, year) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_REPORTS_ONBOARDED_API_URL}`,
      {
        params: {
          physician_id,
          month,
          year,
        },
      }
    ),
  getVitalSigsmonitoringReport: (physician_id, month, year) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.VITAL_SIGNS_MONITORING_REPORT_API_URL}`,
      {
        params: {
          physician_id,
          month,
          year,
        },
      }
    ),
  getDevicesList: (search_text) =>
    Axiosinstance.get(API_URL_CONSTANTS.DEVICES_LIST_API_URL, {
      params: {
        search_text,
      },
    }),
  getBillingReport: (physician_id, month, year, siteName) =>
    Axiosinstance.get(API_URL_CONSTANTS.REPORT_BILLING_API_URL, {
      params: {
        physician_id,
        month,
        year,
        site: siteName,
      },
    }),
};

export { report };
