import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Notfound from "../components/common/Notfound";

import Offlinepage from "../components/common/OfflinePage/Offlinepage";
import { useAdvancedAnalytics } from "../hooks/AdvancedAnalyticsService";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PublicRoute from "./PublicRoute/PublicRoute";
import routes from "./Routes";

export default function AppRoute() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { trackEvent, trackPageView } = useAdvancedAnalytics();
  const location = useLocation();

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      trackEvent("Network Status Change", { status: "online" });
    };
    const handleOffline = () => {
      setIsOnline(false);
      trackEvent("Network Status Change", { status: "offline" });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [trackEvent]);

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location, trackPageView]);

  const DefaultRoute = () => {
    const isAuthenticated = !!localStorage.getItem("token"); // Adjust based on your auth logic
    trackEvent("Default Route Access", { isAuthenticated });
    return isAuthenticated ? (
      <Navigate to="/dashboard" />
    ) : (
      <Navigate to="/login" />
    );
  };

  if (!isOnline) {
    return <Offlinepage />;
  }

  return (
    <Routes>
      <>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.isPrivate ? (
                <PrivateRoute>{route.element}</PrivateRoute>
              ) : (
                <PublicRoute>{route.element}</PublicRoute>
              )
            }
          />
        ))}
        <Route path="/" element={<DefaultRoute />} />
        <Route path="*" element={<Notfound />} />
      </>
    </Routes>
  );
}
