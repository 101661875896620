import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const staff = {
  getstaffList: (role, limit, offset, search_text, status) =>
    Axiosinstance.get(API_URL_CONSTANTS.STAFF_USER_API_URL, {
      params: {
        role,
        limit,
        offset,
        search_text,
        status,
      },
    }),
  createStaff: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.STAFF_USER_API_URL, data),
  assignStaff: (data) =>
    Axiosinstance.put(API_URL_CONSTANTS.STAFF_ASSIGN_API_URL, data),
  getprivilegesList: (id) =>
    Axiosinstance.get(API_URL_CONSTANTS.PRIVILEGES_API_URL, {
      params: {
        user_id: id,
      },
    }),
  getRolewiseprivileges: (role) =>
    Axiosinstance.get(API_URL_CONSTANTS.PRIVILEGES_GROUPED_API_URL, {
      params: {
        role_id: role,
      },
    }),
  getRoleList: () =>
    Axiosinstance.get(API_URL_CONSTANTS.PRIVILEGES_ROLES_API_URL),
  getstaffProfile: (id) =>
    Axiosinstance.get(API_URL_CONSTANTS.STAFF_USER_PROFILE_API_URL, {
      params: {
        id,
      },
    }),
  updateStaffInfo: (id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.STAFF_USER_API_URL}${id}`, data),
  removeStaffMemeber: (id) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.STAFF_USER_API_URL}${id}`),
  checkStaffUser: (email) =>
    Axiosinstance.get(API_URL_CONSTANTS.STAFF_USER_CHECK_API_URL, {
      params: {
        email,
      },
    }),
};
export { staff };
