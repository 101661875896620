import { Button, Result } from "antd";
import React, { Component } from "react";
import "./ErrorBoundary.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error(error, errorInfo);
  }

  resetErrorBoundary = () => {
    this.setState({ hasError: false, errorMessage: "" });
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // Check if the error is related to CSS chunk loading
      const isCSSchunkError =
        this.state.errorMessage.includes("Loading CSS chunk");

      if (isCSSchunkError) {
        window.location.reload();
        // Fallback UI when a CSS chunk loading error occurs
        return (
          <div className="error-boundary">
            <Result
              status="error"
              title="Oops, something went wrong!"
              subTitle="There was an issue loading the application's CSS. Please try again or contact our support team for assistance."
              extra={
                <Button type="primary" onClick={this.resetErrorBoundary}>
                  Try Again
                </Button>
              }
            />
          </div>
        );
      } else {
        // Fallback UI for other errors
        return (
          <div className="error-boundary">
            <Result
              status="error"
              title="Oops, something went wrong!"
              subTitle="Please try again or contact our support team for assistance."
              extra={
                <Button type="primary" onClick={this.resetErrorBoundary}>
                  Try Again
                </Button>
              }
            />
          </div>
        );
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
