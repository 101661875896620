import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import CustomNotification from "../../components/common/CustomNotification";
import { assessment } from "../../services/assessment";
import {
  setButtonLoadingFalse,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

export const submitAssessmentForm = createAsyncThunk(
  "create/form",
  async ({ id, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await assessment.submitAssessment(id, data);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const getAssessmentForm = createAsyncThunk(
  "get/form",
  async ({ id, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await assessment.getOrgForm(id);
      if (response?.data?.id) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
// submitNotes
export const submitNotes = createAsyncThunk(
  "create/notes",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await assessment.submitNotes(data);

      if (response?.data?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
// getNotes
export const getNotes = createAsyncThunk(
  "get/notes",
  async ({ patientId, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await assessment.getNotes(patientId);

      if (response?.data) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
// updateNotes
export const updateNotes = createAsyncThunk(
  "update/notes",
  async ({ id, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await assessment.updateNotes(id, data);
      if (response?.data?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
const formSlice = createSlice({
  name: "form",
  initialState: {
    AssessmentForminfo: [],
    notesinfo: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssessmentForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssessmentForm.fulfilled, (state, action) => {
        state.loading = false;
        state.AssessmentForminfo = action.payload;
      })
      .addCase(getAssessmentForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getNotes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.notesinfo = action.payload;
      })
      .addCase(getNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default formSlice.reducer;
