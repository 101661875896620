import { AutoComplete, Card, Divider, Input, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDrawer from "../../../components/common/CustomDrawer";
// import CustomTooltip from '../../../components/common/CustomTooltip';
// import CustomButton from '../../components/common/CustomButton';

// import { getForm } from '../../actions/patientActions';
// import { ASSESSMENT_QUESTIONNAIRES_PAGE_PATH } from '../../constants/routes';

import { ReactComponent as ArrowRightSVG } from "../../../assets/images/arrow_next.svg";
import { ReactComponent as ProfileSVG } from "../../../assets/images/profile_img.svg";
import CustomButton from "../../../components/common/CustomButton";
import CustomTooltip from "../../../components/common/CustomTooltip/CustomTooltip";
import { ASSESSMENT_QUESTIONNAIRES_PAGE_PATH } from "../../../route/PathConstants";
import { closeDrawer, openDrawer } from "../../../store/Commn/commonSlice";
import { getForm } from "../../../store/forms/formsSlice";
import {
  fecthAssessmentpatientList,
  fetchPatientAssessment,
  resetDataState,
} from "../../../store/patient/patientSlice";
import { formatDateTime } from "../../../utils/helpers";
import "./patientAssessmentDrawer.scss";
const PatientAssessmentDrawer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [patientId, setPatientId] = useState();
  const [searchOptions, setSearchOptions] = useState([]);
  // selectedPatientName
  const [selectedPatientName, setSelectedPatientName] = useState("");
  // const [drawerOpen, setDrawerOpen] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { AssessmentpatientList: patientList, patientAssessment } = useSelector(
    (state) => state?.patient
  );
  const drawerOpen = useSelector((state) => state.common.drawerOpen);

  const handleNavigate = (path, id, interventionid, episode_id, patient_id) => {
    dispatch(
      getForm({
        id: id,
        handlenavigate: () => {
          navigate(path, {
            state: {
              formid: id,
              patientId: patientId,
              interventionid,
              episode_id,
              patient_id,
            },
          });
          dispatch(closeDrawer());
        },
      })
    );
  };

  const renderInsuranceFields = (patient, fields) => (
    <div className="insurance_field">
      {fields.map((field) => (
        <div key={field.label} className="data_fields">
          <p>{field.label}</p>
          <CustomTooltip
            title={patient[field.value]}
            content={<h6>{patient[field.value]}</h6>}
          ></CustomTooltip>
        </div>
      ))}
    </div>
  );

  const renderPatientCard = (patient) => (
    <Card className="search_card">
      <div className="patient_name_field">
        <CustomTooltip
          title={`${patient.firstname} ${patient.lastname}`}
          content={<h6>{`${patient.firstname} ${patient.lastname}`}</h6>}
        ></CustomTooltip>
        <CustomTooltip
          title={`ID:${patient?.patient_id}`}
          content={<p>ID:{patient?.patient_id}</p>}
        ></CustomTooltip>
      </div>

      {renderInsuranceFields(patient, [
        {
          label: "Primary Insurance Carrier Name",
          value: "primary_insurance_carrier_name",
        },
        {
          label: "Primary Insurer Member ID",
          value: "primary_insurer_member_id",
        },
      ])}
    </Card>
  );
  useEffect(() => {
    dispatch(openDrawer());
  }, []);

  useEffect(() => {
    if (patientAssessment?.id) {
      setPatientId(patientAssessment?.id);
    }
  }, [patientAssessment?.id]);

  useEffect(() => {
    if (patientList?.data?.length > 0 && drawerOpen) {
      setSearchOptions(
        patientList.data.map((patient) => ({
          value: patient.id,
          label: renderPatientCard(patient),
        }))
      );
    }
  }, [patientList, drawerOpen]);
  const handleDrawerClose = () => {
    dispatch(closeDrawer());
    setSearchOptions(false);
    setPatientId();

    dispatch(
      fetchPatientAssessment({
        patientId: "",
      })
    );
  };
  const handlePatientViewNavigate = () => {
    dispatch(resetDataState("patientInfo"));
    const url = `/patient/view/${patientId}`;
    navigate(url);
    dispatch(closeDrawer());
  };
  const onSelect = (value, option) => {
    // Find the patient with the specified ID
    const foundPatient = patientList.data.find(({ id }) => id === value);

    // If the patient is found, set the selected patient's full name; otherwise, set it to "Patient not found"
    const fullName = foundPatient
      ? `${foundPatient.firstname} ${foundPatient.lastname}`
      : "Patient not found";

    dispatch(
      fetchPatientAssessment({
        patientId: value,
      })
    );
    setPatientId(value);
    setSelectedPatientName(fullName); // Assuming you have state for selected patient name
  };

  const handleSearch = (value) => {
    dispatch(
      fecthAssessmentpatientList({
        // role: activeTab,
        limit: pageSize,
        offset: (currentPage - 1) * pageSize,
        search_text: value,
      })
    );
    setSelectedPatientName(value);
  };
  // Function to check if the date is overdue or overdue today
  const checkOverdue = (createdOn, today = false) => {
    const createdOnDate = moment(createdOn, moment.ISO_8601).startOf("day");
    const todayDate = moment().startOf("day");
    if (today) {
      return (
        createdOnDate.isBefore(todayDate) && !createdOnDate.isSame(todayDate)
      );
    } else {
      return createdOnDate.isBefore(todayDate);
    }
  };
  return (
    // <h1>hello</h1>
    <CustomDrawer
      title="Fill out a Patient Assessment"
      open={drawerOpen}
      onClose={handleDrawerClose}
      className="assesment_drawer"
    >
      <div className="assesment_drawer_content">
        {!patientAssessment?.fullname ? (
          <div className="search_info">
            <p className="instruction">
              You can search for a patient and fill out any open assessments on
              their behalf.
            </p>
            <AutoComplete
              options={(searchOptions || []).filter((option) => option)}
              onSelect={(value, option) => onSelect(value, option.title)}
              onSearch={handleSearch}
              // dropdownMatchSelectWidth={252}
              dropdownRender={(menu) => <div>{menu}</div>}
              optionLabelProp="label"
              popupClassName="custom-popup-class"
              open="true"
              value={selectedPatientName}
              //  filterOption={(inputValue, option) =>
              //         option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              //       }
              renderOption={(option) => (
                <div>
                  <img
                    src={option.cover}
                    alt={option.label}
                    style={{ width: 50, marginRight: 8 }}
                  />
                  {option.title}
                </div>
              )}
            >
              <Input.Search
                size="large"
                placeholder="Search by Patient Name"
                enterButton
              />
            </AutoComplete>
            <p>
              We recommend you obtain the patient's consent before you fill out
              any assessment on their behalf.
            </p>
          </div>
        ) : (
          <div className="assesment_profile">
            {patientAssessment.fullname ? (
              <div className="profile_details">
                <ProfileSVG className="patient_img" />
                <div>
                  <h5>{patientAssessment.fullname}</h5>
                  <p>{`${
                    new Date().getFullYear() -
                    new Date(patientAssessment.dob).getFullYear()
                  } years, ${patientAssessment.gender}`}</p>
                  <p>{`DOB: ${moment(patientAssessment?.dob).format(
                    "MMM DD, YYYY"
                  )}`}</p>
                </div>
                {/* <img
                  src={require("../../../assets/images/phone_img.png")}
                  alt=""
                  className="phone_img"
                /> */}
              </div>
            ) : null}
            {patientAssessment?.fullname ? (
              <>
                <CustomButton
                  className="view_btn"
                  onClick={handlePatientViewNavigate}
                >
                  View Profile
                </CustomButton>

                {patientAssessment?.form_details?.[0]?.form_name &&
                patientAssessment?.form_details?.length > 0 ? (
                  patientAssessment?.form_details?.map((form, index) => (
                    <React.Fragment key={index}>
                      <div className="carePlan_assesment_info">
                        <div className="carePlan_status">
                          <Tag>Care Plan Assessment</Tag>
                          <h5>{form.form_name}</h5>

                          <p>
                            {`Last measured: ${
                              form.last_measured
                                ? formatDateTime(form.last_measured)
                                : "Not measured yet"
                            }`}
                          </p>
                        </div>
                        {form.form_name && (
                          <>
                            <p
                              style={{
                                color: checkOverdue(form.created_on, true)
                                  ? "red"
                                  : "",
                              }}
                            >
                              {checkOverdue(form.created_on, true)
                                ? "Overdue"
                                : "Due Today"}
                            </p>
                            <ArrowRightSVG
                              className="arrow_next"
                              onClick={() =>
                                handleNavigate(
                                  ASSESSMENT_QUESTIONNAIRES_PAGE_PATH,
                                  form.form_id,
                                  form.id,
                                  form.episode_id,
                                  form.patient_id
                                )
                              }
                            />
                          </>
                        )}
                      </div>
                      {index < patientAssessment.form_details.length - 1 && (
                        <Divider />
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <p>No assessment forms available for this patient.</p>
                )}
              </>
            ) : null}
          </div>
        )}
      </div>
    </CustomDrawer>
  );
};

export default PatientAssessmentDrawer;
