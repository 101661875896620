import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const site = {
  getsiteList: (limit, offset, search_text) =>
    Axiosinstance.get(API_URL_CONSTANTS.ORG_SITE_ALL_API_URL, {
      params: {
        limit,
        offset,
        search_text,
      },
    }),
  createSite: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.ORG_SITE_API_URL, data),
  getsiteinfo: (id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.ORG_SITE_API_URL}${id}`),
  deleteSiteInfo: (id) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.ORG_SITE_API_URL}${id}`),
  updateSiteInfo: (id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.ORG_SITE_API_URL}${id}`, data),
};
export { site };
