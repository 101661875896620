const API_URL_CONSTANTS = {
  LOGIN_API_URL: "/auth/login",
  LOGOUT_API_URL: "/auth/logout",
  STAFF_USER_API_URL: "/staff/user/",
  // STAFF_USER_PUBLIC_ID_API_URL: '/staff/user/{public_id}',
  ORG_SITE_ALL_API_URL: "/org/site/all",
  ORG_SITE_API_URL: "/org/site/",
  ORG_PROFILE_TAG_API_URL: "/org/profile-tag/",
  ORG_PROFILE_TAG_ALL_API_URL: "/org/profile-tag/all",

  PRIVILEGES_API_URL: "/privileges/",
  PRIVILEGES_ROLES_API_URL: "/privileges/roles",
  PRIVILEGES_GROUPED_API_URL: "/privileges/grouped",
  AUTH_REFRESH_API_URL: "/auth/refresh/token",
  RESET_PASSWORD_API_URL: "/auth/reset/password",
  STAFF_USER_PROFILE_API_URL: "/staff/user/profile",
  STAFF_USER_CHECK_API_URL: "/staff/user/check",
  STAFF_USER_PERMISSIONS_API_URL: "/staff/user/permissions",
  PASSWORD_RESET_API_URL: "/auth/password/reset",
  STAFF_ASSIGN_API_URL: "/staff/user/assign",
  PATIENT_API_URL: "/patient/",
  PATIENT_EPISODE_API_URL: "/patient/episode/",
  DEVICE_API_URL: "/patient/device/",
  ALLERGIES_API_URL: "/patient/allergies/",
  CONTACT_API_URL: "/patient/contact/",
  GUARDIAN_API_URL: "/patient/guardian/",
  ORGANIZATION_FORM_API_URL: "/org/form/",
  GET_ALL_ORG_FORMS_API_URL: "/org/form/all",
  ORG_FORM_NAMES_API_URL: "/org/form/names/all",
  REPORT_CMT_COMPLETED_API_URL: "/report/cmt/completed",
  TMS_TIME_REPORT_API_URL: "/report/tms/time",
  TMS_TIME_REPORT_A_API_URL: "/report/tms/time/A",
  TMS_TIME_REPORT_B_API_URL: "/report/tms/time/B",
  VITAL_SIGNS_MONITORING_REPORT_API_URL: "/report/vital/signs/monitoring", // URL for vital signs monitoring reports
  DEVICE_SETUP_REPORT_API_URL: "/report/device/setup",
  CARE_PLAN_API_URL: "/org/careplan/",
  GET_ALL_CARE_PLAN_API_URL: "/org/careplan/all",
  ASSIGN_CAREPLAN_TO_PATIENT_EPISODE_API_URL:
    "/patient/episode/assign/careplan",
  GET_ALL_CARE_PLAN_ASSIGNMENTS_API_URL:
    "/patient/episode/careplan/assignments",
  PATIENT_EPISODE_ASSIGN_CAREPLAN_API_URL: "/patient/episode/assign/careplan",
  PATIENT_EPISODE_CAREPLAN_LIST_API_URL:
    "/patient/episode/careplan/intervention",
  SUSPEND_PATIENT_EPISODE_API_URL: "/patient/episode/suspend/",
  PATIENT_ASSESSMENT_API_URL: "/patient/assessment/search",
  PATIENT_EPISODE_CAREPLAN_API_URL: "/patient/episode/careplan",
  // '  /patient/episode/careplan/intervention/form/{episode_intervention_id}' Wrtite varable name
  PATIENT_EPISODE_CAREPLAN_INTERVENTION_FORM_API_URL:
    "/patient/episode/careplan/intervention/form/",
  // /patient/notes
  PATIENT_NOTES_API_URL: "/patient/notes",
  PATIENT_DISCHARGE_API_URL: "/patient/discharge",
  PATIENT_NOT_ONBOARDED_API_URL: "/patient/not-onboarded",

  // /patient/episode/careplan/intervention/form/completed/{episode_intervention_id}

  PATIENT_EPISODE_CAREPLAN_INTERVENTION_FORM_COMPLETED_API_URL:
    "/patient/episode/careplan/intervention/form/completed/",

  PATIENT_EPISODE_LOG_API_URL: "/patient/episode/log/",

  PATIENT_EPISODE_LOG_ACTIVITY_ALL_API_URL: "/patient/episode/log/activity/all",
  ALERT_CONFIG_LIST_API_URL: "/alert/config/list",
  ALERT_CONFIG_API_URL: "/alert/config/",
  NOTIFICATION_API_URL: "/notification",
  NOTIFICATION_LIST_API_URL: "/notification/list",
  NOTIFICATION_COUNT_API_URL: "/notification/count",
  NOTIFICATION_PATIENT_API_URL: "/notification/patient",
  PATIENT_EPISODE_LOG_ALL_API_URL: "/patient/episode/log/all/",
  PATIENT_FORMS_API_URL: "/patient/forms/",
  PATIENT_ASSESSMENT_TREND_API_URL: "/patient/assessment/trend/",
  PATIENT_CHECK_API_URL: "/patient/check",
  ORG_VITAL_SIGN_ALL_API_URL: "/org/vital-sign/all",
  ORG_VITAL_SIGN_API_URL: "/org/vital-sign",
  TMS_LOG_API_URL: "/patient/episode/tms/log",
  DEVICE_SETUP_TYPES_ALL_API_URL:
    "/patient/episode/device/setup/task/types/all",
  DEVICE_SETUP_API_URL: "/patient/episode/device/setup/task/",
  DEVICE_SETUP_TASKS_ALL_API_URL: "/patient/episode/device/setup/task/all/",
  DEVICES_LIST_API_URL: "/report/devices/list",
  PATIENT_EPISODE_DEVICE_SETUP_TASK_API_URL:
    "/patient/episode/device/setup/task",

  QUICK_VIEW_DASHBOARD_API_URL: "/dashboard/quick/view",
  DASHBOARD_METRICS_API_URL: "/dashboard/metrics",
  PATIENT_VITAL_TREND_API_URL: "/patient/vital/trend",
  EPISODE_TYPES_API_URL: "/patient/episode/types",
  SMS_TEMPLATES_API_URL: "/sms/templates/",
  SMS_TEMPLATES_LIST_API_URL: "/sms/templates/list",
  SMS_LIST_API_URL: "/sms/list",
  SMS_MARK_AS_READ_API_URL: "/sms/mark/as/read",
  SMS_API_URL: "/sms/",
  SETTINGS_API_URL: "/settings/config",
  UPDATE_SETTINGS_API_URL: "/settings/update",
  ALL_DEVICE_TYPES_API_URL: "/patient/device/types/all",
  PATIENT_REPORTS_API_URL: "/report/patients",
  PATIENT_REPORTS_ONBOARDED_API_URL: "/report/patients/onboarded",
  REPORT_SHARE_API_URL: "/report/share",
  REPORT_BILLING_API_URL: "/report/billing",
  PRICE_TEMPLATES_API_URL: "/price/templates/",

  // invoice
  INVOICE_API_URL: "/invoice/",
  INVOICE_LIST_API_URL: "/invoice/list",
  INVOICE_PAYMENT_LINK_API_URL: "/invoice/payment/link",
  INVOICE_SENT_API_URL: "/invoice/sent",
};
export default API_URL_CONSTANTS;
