import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { settoken } from "../../utills/commonData";

import CustomNotification from "../../components/common/CustomNotification";
import { profileTags } from "../../services/profileTags";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

export const createProfileTags = createAsyncThunk(
  "create/profileTags",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await profileTags.createProfileTags(data);

      if (response.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        if (response?.data.status) {
          handlenavigate(response?.data.status);
          //   settoken(response?.data?.authorization);
          CustomNotification(
            "success",
            "Success",
            '"sucessfully profileTag added"'
          );

          return response?.data;
        }
        // thunkAPI.dispatch(setLoadingFalse());
        // message.error("Require admin approval");
      } else {
        thunkAPI.dispatch(setLoadingFalse());
        // message.error("something when wrong please try again");
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // message.error("something when wrong please try again");
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const fecthprofileTagsList = createAsyncThunk(
  "fetch/profileTagsList",
  async (params, thunkAPI) => {
    const { limit, offset, search_text } = params;
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await profileTags.getProfileTagsList(
        limit,
        offset,
        search_text
      );
      if (response && response.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const UpdateprofileTaginfo = createAsyncThunk(
  "fetch/UpdateprofileTaginfo",
  async ({ ProfileTagId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await profileTags.updateprofileTagInfo(
        ProfileTagId,
        data
      );
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification(
          "success",
          "Success",
          '"Sucessfully profileTag Updated"'
        );

        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const fetchprofileTaginfo = createAsyncThunk(
  "fetch/fetchprofileTaginfo",
  async ({ itemId, handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await profileTags.getprofileTaginfo(itemId);

      if (response?.data.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
export const RemoveprofileTaginfo = createAsyncThunk(
  "fetch/RemoveprofileTaginfo",
  async ({ currentTag, fetchData }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await profileTags.deleteprofileTagInfo(currentTag);

      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());

        fetchData();
        CustomNotification("success", "Success", response.data.status);
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
const profileTagsSlice = createSlice({
  name: "profileTags",
  initialState: {
    profileTagsList: [],
    updatedprofile: [],
    profileTaginfo: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createProfileTags.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProfileTags.fulfilled, (state, action) => {
        state.loading = false;
        state.profileTaginfo = action.payload;
      })
      .addCase(createProfileTags.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fecthprofileTagsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthprofileTagsList.fulfilled, (state, action) => {
        state.loading = false;
        state.profileTagsList = action.payload;
      })
      .addCase(fecthprofileTagsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchprofileTaginfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchprofileTaginfo.fulfilled, (state, action) => {
        state.loading = false;
        state.profileTaginfo = action.payload;
      })
      .addCase(fetchprofileTaginfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default profileTagsSlice.reducer;
