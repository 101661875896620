import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import storage from "redux-persist/lib/storage";
import { common } from "../../services/Common";
// import { settoken } from "../../utills/commonData";

import CustomNotification from "../../components/common/CustomNotification";
import { setIdtoken, settoken } from "../../utils/token";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";
const roleNames = ["TENANT_ADMIN", "PHYSICIAN", "MONITORING_STAFF"];
export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ data, handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());

    try {
      const response = await common.userSignIn(data);

      if (response.data.status) {
        thunkAPI.dispatch(setLoadingFalse());

        if (response?.data.authorization) {
          handlerouter(response?.data);
          settoken(response?.data?.authorization);
          setIdtoken(response?.data?.id_token);
          CustomNotification(
            "success",
            "Successfully Logged In",
            "You are now logged in."
          ); // Use CustomNotification
          return response?.data;
        }
      } else {
        thunkAPI.dispatch(setLoadingFalse());
        handlerouter(response);
        // CustomNotification('error', 'Something went wrong, please try again', ''); // Example for error notification
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      handlerouter(error.response?.status);
      // if (error.response?.status === 401) {
      //   CustomNotification("error", "Error", "Invalid username or password");
      // }
      // CustomNotification('error', 'Something went wrong, please try again', ''); // Example for error notification
      return thunkAPI.rejectWithValue(error);
    } finally {
      thunkAPI.dispatch(setLoadingFalse());
    }
  }
);

export const fecthuserProfile = createAsyncThunk(
  "fetch/fecthuserProfile",
  async (userId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await common.getProfileInfo();

      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);
const isReloadConditionMet = (failedRequest) => {
  // Customize this condition based on your requirements
  // For example, check if it's a GET request
  return (
    failedRequest &&
    failedRequest.config &&
    failedRequest.config.method === "get"
  );
};
export const fecthrefreshToken = createAsyncThunk(
  "fetch/fecthrefreshToken",
  async (failedRequest, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await common.getRefreshToken();

      if (response.data.status) {
        thunkAPI.dispatch(setLoadingFalse());

        if (response?.data.authorization) {
          // // Update the Redux store with the refreshed token
          // thunkAPI.dispatch(updateToken(response.data.authorization)
          settoken(response?.data?.authorization);
          setIdtoken(response?.data?.id_token);

          window.location.reload();
          // // Return the response data
          // if (isReloadConditionMet(failedRequest)) {
          //   // Reload the page
          // }
          return response.data;
        }
      } else {
        // window.location.reload();
        // Handle the case where refreshing the token was not successful
        thunkAPI.dispatch(setLoadingFalse());
        CustomNotification(
          "error",
          "Token Refresh Error",
          "Something went wrong while refreshing the token."
        );
        thunkAPI.dispatch(logoutreducers());
      }
    } catch (error) {
      // window.location.reload();
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(logoutreducers());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const updateprofielinfo = createAsyncThunk(
  "auth/updatedprofile",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await common.getUpdatedProfileinfoById(id);
      if (response.status === 200) {
        thunkAPI.dispatch(setLoadingFalse());
        return response?.data?.data;
      } else {
        thunkAPI.dispatch(setLoadingFalse());
        message.error("something went wrong");
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const userLogOut = createAsyncThunk(
  "auth/UserlogOut",
  async ({ handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await common.userSignOut({});
      if (response.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlerouter(response.data.message);
        CustomNotification("success", response.data.message, ""); // Display success notification
        return response?.data;
      }
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("error", "Something went wrong", ""); // Display error notification
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("error", "Something went wrong", ""); // Display error notification
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async ({ data, handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await common.updatePassword(data);

      if (response.status) {
        thunkAPI.dispatch(setLoadingFalse());
        message.success("User updated successfully");
        handlerouter();
        // return response?.data?.data?.profile;
      } else {
        thunkAPI.dispatch(setLoadingFalse());
        message.error("something when wrong please try again");
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      message.error("something when wrong please try again");
    }
  }
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await common.resetPassword(data);
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("success", "Success", "Password reset successfully");
      handlenavigate(); // Assuming handlenavigate is a function to handle navigation
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const fecthAllRoleList = createAsyncThunk(
  "fetch/commonfecthRoleList",
  async (userId, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await common.getRoleList();
      console.log("fecthAllRoleList", response?.data?.data);
      if (response?.status) {
        const rolesData = response.data.data.filter((role) =>
          roleNames.includes(role.name)
        );
        rolesData.forEach((role) =>
          localStorage.setItem(`${role.name}_ID`, role.id)
        );
      }
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    } finally {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
    }
  }
);

export const fetchUserPermissions = createAsyncThunk(
  "staff/fetchUserPermissions",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await common.getUserPermissions();
      console.log("fetchUserPermissions", response);
      thunkAPI.dispatch(setLoadingFalse());
      // CustomNotification(
      //   "success",
      //   "Success",
      //   "User permissions fetched successfully"
      // );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("error", "Error", "Failed to fetch user permissions");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const loginSlice = createSlice({
  name: "login",
  initialState: {
    profileinfo: [],
    profileErrorinfo: [],
    userinfo: [],
    Roles: [],
    updatedprofile: [],
    loading: false,
    error: null,
    userPermissions: [],
  },
  reducers: {
    logoutreducers(state) {
      storage.removeItem("persist:root");
      localStorage.clear();
      // window.location.reload();
      state.profileinfo = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.loading = true;
        state.profileErrorinfo = null; // Reset error info on pending
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.profileinfo = action.payload;
        state.profileErrorinfo = null; // Clear error info on success
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.profileinfo = null; // Clear profile info on error
        state.profileErrorinfo = action.payload; // Set error info
      })
      .addCase(fecthrefreshToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthrefreshToken.fulfilled, (state, action) => {
        state.loading = false;
        state.profileinfo = action.payload;
      })
      .addCase(fecthrefreshToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        storage.removeItem("persist:root");
        state.profileinfo = [];
        window.location.reload();
      })
      .addCase(fecthuserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthuserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userinfo = action.payload;
      })
      .addCase(fecthuserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(userLogOut.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogOut.fulfilled, (state, action) => {
        state.loading = false;
        storage.removeItem("persist:root");
        state.profileinfo = [];
      })
      .addCase(userLogOut.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateprofielinfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateprofielinfo.fulfilled, (state, action) => {
        state.loading = false;
        state.updatedprofile = action.payload;
      })
      .addCase(updateprofielinfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fecthAllRoleList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fecthAllRoleList.fulfilled, (state, action) => {
        state.loading = false;
        state.Roles = action.payload;
      })
      .addCase(fecthAllRoleList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUserPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.userPermissions = action.payload;
      })
      .addCase(fetchUserPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logoutreducers } = loginSlice.actions;

export default loginSlice.reducer;
