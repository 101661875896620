import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const dashboardervice = {
  getdashboardsinfo: () =>
    Axiosinstance.get(API_URL_CONSTANTS.QUICK_VIEW_DASHBOARD_API_URL, {
      // params: { is_active },
    }),
  getdashboardsmetrics: (is_active) =>
    Axiosinstance.get(API_URL_CONSTANTS.DASHBOARD_METRICS_API_URL, {
      params: { is_active },
    }),
};
export { dashboardervice };
