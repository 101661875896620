import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import CustomNotification from "../../components/common/CustomNotification";
import { alertservice } from "../../services/alert";
import { setLoadingFalse, setLoadingTrue } from "../SpinSlice/SpinSlice";

export const fetchAlertConfigList = createAsyncThunk(
  "alertConfigList/fetchAll",
  async (params, thunkAPI) => {
    const { is_active } = params;
    thunkAPI.dispatch(setLoadingTrue());

    try {
      const response = await alertservice.getAlerts(is_active);
      //
      // CustomNotification(
      //   "success",
      //   "Successfully Logged In",
      //   "You are now logged in."
      // );
      thunkAPI.dispatch(setLoadingFalse());
      return response.data.data; // Return the response data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      //   return thunkAPI.rejectWithValue(error);
      // Rejecting with value to trigger the `rejected` action
    }
  }
);

export const updateAlerts = createAsyncThunk(
  "alerts/updateAlerts",
  async ({ id, data }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue()); // Set loading state to true
    try {
      const response = await alertservice.updateAlerts(id, data);
      CustomNotification(
        "success",
        "Alert Updated",
        "The alert has been updated successfully."
      );
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false
      return response.data; // Return the updated alert data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse()); // Set loading state to false in case of error
      return thunkAPI.rejectWithValue(error.response.data); // Reject with value to trigger the 'rejected' action
    }
  }
);

const initialState = {
  alertConfigList: [],
  loading: false,
  error: null,
};

const alertConfigListSlice = createSlice({
  name: "alertConfigList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlertConfigList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAlertConfigList.fulfilled, (state, action) => {
        state.loading = false;
        state.alertConfigList = action.payload;
      })
      .addCase(fetchAlertConfigList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default alertConfigListSlice.reducer;
