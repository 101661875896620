import { BellOutlined, DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Badge, Menu, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import label from "./../../../../../assets/internationalization/en-US.json";
// Add imports for other menu items

import {
  ALERTS_PAGE_PATH,
  CAREPLAN_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  DIAGNOSIS_CODES_PAGE_PATH,
  FORMS_PAGE_PATH,
  HELP_GUIDES_PAGE_PATH,
  INVOICE_OVER_VIEW_PATH,
  LOGIN_PAGE_PATH,
  NOTIFICATIONS_PAGE_PATH,
  PRICES_PAGE_PATH,
  PROFILE_TAGS_PAGE_PATH,
  REPORTS_PAGE_PATH,
  SETTINGS_PAGE_PATH,
  SITES_PAGE_PATH,
  SMS_PAGE_PATH,
  VITAL_SIGNS_PAGE_PATH,
} from "../../../../../route/PathConstants";

import { ReactComponent as HelpGuideSVG } from "../../../../../assets/images/helpGuideSvg.svg";
import {
  hasPermission,
  usePermissions,
} from "../../../../../hooks/usePermissions";
import PatientAssessmentDrawer from "../../../../../pages/quick-actions/patientAssessmentDrawer/patientAssessmentDrawer";
import { userLogOut } from "../../../../../store/Auth/AuthSlice";
import { openDrawer } from "../../../../../store/Commn/commonSlice";
import { resetDataState } from "../../../../../store/patient/patientSlice";
import { setDynamicFavicon } from "../../../../../utils/helpers";
import CustomDropdown from "../../../CustomDropdown";
import "./ContainerHeader.scss";
const { SubMenu } = Menu;
// fetchp

const ContainerHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const { firstname, lastname } =
    useSelector((state) => state?.auth?.userinfo) ?? {};
  const drawerOpen = useSelector((state) => state.common.drawerOpen);
  const { settingsData } = useSelector((state) => state?.settings);
  const { notificationscount, notifications, loading, error } = useSelector(
    (state) => state.notifications
  );
  const permissions = usePermissions();

  const menudata = [
    {
      key: DASHBOARD_PAGE_PATH,
      label: label["MENU.DASHBOARD"],
      // icon: <DashboardSVG className="svgImg" />,
      // activeIcon: <DashboardActiveSVG className="svgImg" />,
    },
    {
      key: "assessment",
      label: label["MENU.ASSESSMENT"],
    },
    {
      key: "staff",
      label: "Staff",
      children: [
        {
          key: "/staff?role=MONITORING_STAFF",
          label: "Monitoring Staff",
        },
        {
          key: "/staff?role=PHYSICIAN",
          label: "Physician",
        },
      ],
    },
    {
      key: "patients",
      label: "Patients",
      children: [
        {
          key: "/patients?filter=ALL_PATIENTS&assignedStaffType=ALL",
          label: "All Patients",
        },
        {
          key: "/patients?filter=GEN_BHI&assignedStaffType=ALL&episode_status=ACTIVE",
          label: "Gen-BHI",
        },
        {
          key: "/patients?filter=RPM&assignedStaffType=ALL&episode_status=ACTIVE",
          label: "RPM",
        },
        {
          key: "/patients?filter=no_active_episode&assignedStaffType=ALL",
          label: "No active episodes",
        },
      ],
    },
    {
      key: REPORTS_PAGE_PATH,
      label: "Reports",
      permission: "VIEW_REPORTS",
      // icon: <ReportsSVG className="svgImg" />,
      // activeIcon: <ReportsActiveSVG className="svgImg" />,
    },
    // {
    //   key: ACTIVE_CARE_PLANS_DETAILS_PAGE_PATH,
    //   label: "Active Care Plans",
    //   // icon: <DashboardSVG className="svgImg" />,
    //   // activeIcon: <DashboardActiveSVG className="svgImg" />,
    // },
    {
      key: "configure",
      label: "Configure",
      children: [
        {
          key: SETTINGS_PAGE_PATH,
          label: "Settings",
        },
        {
          key: SITES_PAGE_PATH,
          label: "Sites",
          permission: "VIEW_SITE",
        },
        {
          key: VITAL_SIGNS_PAGE_PATH,
          label: "Vital Signs",
        },
        {
          key: INVOICE_OVER_VIEW_PATH,
          label: "Invoice",
          permission: "VIEW_INVOICE",
        },
        {
          key: FORMS_PAGE_PATH,
          label: "Forms",
        },
        {
          key: ALERTS_PAGE_PATH,
          label: "Alerts",
        },
        {
          key: PROFILE_TAGS_PAGE_PATH,
          label: "Profile Tags",
          permission: "VIEW_TAG",
        },
        {
          key: DIAGNOSIS_CODES_PAGE_PATH,
          label: "Diagnosis Codes",
        },
      ].filter(
        (item) =>
          !item.permission || hasPermission(permissions, item.permission)
      ),
    },

    {
      key: "templates",
      label: "Templates",
      // icon: <TemplateSVG className="svgImg" />,
      // activeIcon: <TemplateActiveSVG className="svgImg" />,
      children: [
        {
          key: CAREPLAN_PAGE_PATH,
          label: "Careplan",
        },

        {
          key: SMS_PAGE_PATH,
          label: "Sms",
        },
        {
          key: PRICES_PAGE_PATH,
          label: "Prices",
        },
      ],
    },
  ].filter(
    (item) => !item.permission || hasPermission(permissions, item.permission)
  );

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.children) {
      // If the clicked item has children, set activeMenuItem to the parent item's linkurl
      setActiveMenuItem(menuItem.linkurl);
    } else {
      // If the clicked item doesn't have children, set activeMenuItem to the clicked item's linkurl
      setActiveMenuItem(menuItem.linkurl ? menuItem.linkurl : undefined);
      // Handle specific actions for menu items if needed
      if (menuItem.label === label["MENU.ASSESSMENT"]) {
        // navigate(ASSESSMENT_PAGE_PATH);
        dispatch(openDrawer());
      }
    }
  };

  const handlenavigate = (path) => {
    navigate(NOTIFICATIONS_PAGE_PATH);
  };

  const handlerouter = (val) => {
    if (val) {
      navigate(LOGIN_PAGE_PATH);
      localStorage.clear();
    }
  };

  const handleClick = (e) => {
    if (e.key === "logout") {
      dispatch(userLogOut({ handlerouter }));
    }
  };
  useEffect(() => {
    if (!drawerOpen) {
      // dispatch(
      //   fetchPatientAssessment({
      //     patientId: "",
      //   })
      // );patientAssessment
      dispatch(resetDataState("patientAssessment"));
      dispatch(resetDataState("AssessmentpatientList"));
    }
  }, [drawerOpen]);
  useEffect(() => {
    if (settingsData?.favicon) {
      setDynamicFavicon(settingsData?.favicon);
    }
  }, [settingsData?.favicon]);

  useEffect(() => {
    // Find the menu item whose linkurl matches the current path
    const foundMenuItem = menudata.find((menuItem) =>
      location.pathname.startsWith(menuItem.linkurl)
    );

    if (foundMenuItem) {
      setActiveMenuItem(
        foundMenuItem.linkurl ? foundMenuItem.linkurl : undefined
      );
    }
  }, [location.pathname]);
  const menuItems = [
    // {
    //   key: "Billing",
    //   label: "Billing",
    //   icon: <BillingSVG className="svgImg" />,
    //   onClick: () => navigate(BILLING_PAGE_PATH),
    // },
    {
      key: "Help-Guides",
      label: "Help-Guides",
      icon: <HelpGuideSVG className="svgImg" />,
      onClick: () => navigate(HELP_GUIDES_PAGE_PATH),
    },
    // {
    //   key: "1",
    //   label: "Change Password",
    //   icon: <UserOutlined />,
    //   onClick: () => navigate(UPDATE_PASSWORD_PAGE_PATH),
    // },

    {
      key: "logout",
      label: "Logout",
      icon: <LogoutOutlined />,
      // onClick: ()=>handleClick('logout'),
    },
  ];

  const handleMenuClick = (menuItem) => {
    if (menuItem.key === "assessment") {
      // navigate(ASSESSMENT_PAGE_PATH);
      dispatch(openDrawer());
    } else {
      navigate(menuItem.key);
    }
  };
  const getSelectedKeys = () => {
    const selectedPath = location.search
      ? location.pathname + location.search
      : location.pathname;
    const selectedKeys = [];

    menuItems.forEach((menuItem) => {
      if (menuItem.key === selectedPath) {
        selectedKeys.push(menuItem.key);
      } else if (menuItem.children) {
        menuItem.children.forEach((child) => {
          if (child.key === selectedPath) {
            selectedKeys.push(child.key);
          }
        });
      }
    });

    return selectedKeys;
  };

  const selectedKeys = getSelectedKeys();

  const extractPathname = (url) => {
    const pattern = "/patients?filter=ALL_PATIENTS&assignedStaffType=ALL";
    const patternIndex = url.indexOf(pattern);
    const pathname =
      patternIndex !== -1 ? url.slice(0, patternIndex + pattern.length) : url;
    return pathname;
  };

  const selectedPath = location.search
    ? extractPathname(`${location.pathname}${location.search}`)
    : location.pathname;

  return (
    <header className="container-header">
      {/* Logo section */}
      <div className="logo-section">
        <div className="logo">
          <img
            src={
              settingsData?.logo
                ? settingsData?.logo
                : require("../../../../../../src/assets/images/AvertiTextLogo.png")
            }
            alt="logo"
          />
        </div>
      </div>
      {/* Menu section */}
      {/* <Menu mode="horizontal" selectedKeys={[activeMenuItem]}>
        {menu.map((menuItem, index) => {
          return menuItem.children ? (
            <SubMenu
              key={`sub${index + 1}`}
              title={menuItem.label}
              style={{
                fontWeight: menuItem.children.some(
                  (subItem) => subItem.linkurl === activeMenuItem
                )
                  ? 600 // Apply the specified fontWeight if any child item is active
                  : 500, // Otherwise, use the default fontWeight
                color:
                  menuItem.children.some(
                    (subItem) => subItem.linkurl === activeMenuItem
                  ) && "var(--primary)",
                borderBottom: menuItem.children.some(
                  (subItem) => subItem.linkurl === activeMenuItem
                )
                  ? "2px solid var(--primary)" // Apply the borderBottom style if any child item is active
                  : "none", // Otherwise, no borderBottom style
              }}
            >
              {menuItem.children.map((subItem, subIndex) => (
                <Menu.Item
                  key={`sub${index + 1}-${subIndex + 1}`}
                  onClick={() => handleMenuItemClick(subItem)}
                  style={{
                    borderBottom:
                      subItem.linkurl === activeMenuItem
                        ? "2px solid var(--primary)"
                        : "none",
                  }}
                >
                  <Link
                    className="hover-link"
                    style={{
                      color:
                        subItem.linkurl === activeMenuItem
                          ? "var(--primary)"
                          : "#000",
                      fontWeight:
                        menuItem.linkurl === activeMenuItem ? 600 : 500,
                    }}
                    to={subItem.linkurl}
                  >
                    {subItem.label}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={index + 1}
              onClick={() => handleMenuItemClick(menuItem)}
              style={{
                borderBottom:
                  menuItem.linkurl === activeMenuItem
                    ? "2px solid var(--primary)"
                    : "",
              }}
            >
              {menuItem.label === "Assessment" ? (
                <span
                  className="hover-link"
                  style={{
                    color:
                      menuItem.linkurl === activeMenuItem
                        ? "var(--primary)"
                        : "#000",

                    borderBottom: "none",
                    fontWeight: menuItem.linkurl === activeMenuItem ? 600 : 500,
                  }}
                >
                  {menuItem.label}
                </span>
              ) : (
                <Link
                  className="hover-link"
                  style={{
                    color:
                      menuItem.linkurl === activeMenuItem
                        ? "var(--primary)"
                        : "#000",

                    borderBottom: "none",
                    fontWeight: menuItem.linkurl === activeMenuItem ? 600 : 500,
                  }}
                  to={menuItem.linkurl}
                >
                  {menuItem.label}
                </Link>
              )}
            </Menu.Item>
          );
        })}
      </Menu> */}
      <Menu
        items={menudata}
        mode="horizontal"
        onClick={handleMenuClick}
        selectedKeys={selectedPath}
        inlineCollapsed={false}
      />
      <div className="header-icons">
        <Badge
          count={notificationscount?.total_count ?? 0}
          onClick={() => handlenavigate(NOTIFICATIONS_PAGE_PATH)}
        >
          <BellOutlined className="notification-icon" />
        </Badge>
        <CustomDropdown
          menuItems={menuItems}
          overlayClassName="user-dropdown user-dropdown-2"
          onMenuClick={handleClick}
        >
          <Space>
            {firstname}
            {lastname}
            <DownOutlined className="user-dropdown-icon" />
          </Space>
        </CustomDropdown>
      </div>
      {drawerOpen && <PatientAssessmentDrawer />}
    </header>
  );
};

export default ContainerHeader;
