// Redux Toolkit
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomNotification from "../../components/common/CustomNotification";
import smsTemplatesService from "../../services/sms";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

// Async thunk for fetching all SMS templates
export const fetchAllTemplates = createAsyncThunk(
  "smsTemplates/fetchAllTemplates",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await smsTemplatesService.getAllTemplates();
      if (response && response.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const createNewTemplate = createAsyncThunk(
  "smsTemplates/createNewTemplate",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await smsTemplatesService.createTemplate(data);

      if (response.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        if (response?.data.status) {
          handlenavigate(response?.data.status);
          CustomNotification(
            "success",
            "Success",
            "Template Created successfully"
          );

          return response?.data;
        }
        // thunkAPI.dispatch(setLoadingFalse());
        // message.error("Require admin approval");
      } else {
        thunkAPI.dispatch(setLoadingFalse());
        // message.error("something when wrong please try again");
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      handlenavigate();
      // message.error("something when wrong please try again");
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const UpdateTemplateInfo = createAsyncThunk(
  "fetch/UpdateTemplateInfo",
  async ({ Id, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await smsTemplatesService.updateTemplateInfo(Id, data);
      if (response?.data?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate(response?.data?.status);
        CustomNotification(
          "success",
          "Success",
          '"Sucessfully Template Updated"'
        );

        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      handlenavigate();
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const RemoveTemplateInfo = createAsyncThunk(
  "fetch/RemoveTemplateInfo",
  async ({ id, data, fetchData }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await smsTemplatesService.deleteTemplateInfo(id, data);

      if (response?.data?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        fetchData(response.data.status);
        CustomNotification("success", "Success", response.data.status);
        // message.success(response.data.status)
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const fetchSMSList = createAsyncThunk(
  "smsTemplates/fetchSMSList",
  async ({ patient_id, fetchdata }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await smsTemplatesService.getSMSList(patient_id);
      if (response && response.status) {
        fetchdata();
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const createNewSMS = createAsyncThunk(
  "smsTemplates/createNewSMS",
  async ({ data, handlenavigate, setIsButtonLoading }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      //
      // const response = [];
      const response = await smsTemplatesService.createSMS(data);
      if (response.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        if (response?.data.status) {
          handlenavigate(response?.data.status);
          //   settoken(response?.data?.authorization);
          CustomNotification("success", "Success", "SMS Created successfully");
          setIsButtonLoading(false);
          return response?.data;
        }
        // thunkAPI.dispatch(setLoadingFalse());
        // message.error("Require admin approval");
      } else {
        thunkAPI.dispatch(setLoadingFalse());
        // message.error("something when wrong please try again");
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      setIsButtonLoading();
      // message.error("something when wrong please try again");
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
export const markSmsAsRead = createAsyncThunk(
  "sms/markAsRead",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await smsTemplatesService.markSmsAsRead(data);
      thunkAPI.dispatch(setLoadingFalse());
      handlenavigate();
      CustomNotification(
        "success",
        "Success",
        "SMS marked as read successfully"
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("error", "Error", "Failed to mark SMS as read");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
// Initial state for SMS templates slice
const initialState = {
  templates: [],
  SMSList: [],
  loading: false,
  error: null,
};

// SMS templates slice
const smsTemplatesSlice = createSlice({
  name: "smsTemplates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Reducer for fetching all SMS templates
    builder.addCase(fetchAllTemplates.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllTemplates.fulfilled, (state, action) => {
      state.loading = false;
      state.templates = action.payload;
    });
    builder.addCase(fetchAllTemplates.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchSMSList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSMSList.fulfilled, (state, action) => {
      state.loading = false;
      state.SMSList = action.payload;
    });
    builder.addCase(fetchSMSList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default smsTemplatesSlice.reducer;
