import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "../locales/en/translation.json";
import elTranslation from "../locales/el/translation.json";

i18n.use(LanguageDetector).init({
  resources: {
    en: { translation: enTranslation },
    el: { translation: elTranslation },
  },
  fallbackLng: "el", // Use Greek ('el') as the fallback language
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ["localStorage", "navigator"],
    lookupLocalStorage: "i18nextLng", // Change this to match your localStorage key
    caches: ["localStorage"],
    checkWhitelist: true,
  },
  // Custom language detection function to handle 'en-US' fallback
  // eslint-disable-next-line no-dupe-keys
  detection: {
    order: ["custom"],
    lookup: () => {
      return "el"; // Force Greek ('el') as the language
    },
  },
});
export const changeLanguage = (languageCode) => {
  i18n.changeLanguage(languageCode);
};
export default i18n;
