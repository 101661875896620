import { combineReducers } from "@reduxjs/toolkit";
import alertSlice from "./Alert/alertSlice";
import AssessmentSlice from "./Assessment/AssessmentSlice";
import LoginSlice from "./Auth/AuthSlice";
import commonSlice from "./Commn/commonSlice";
import reportsSlice from "./Reports/reportsSlice";
import settingsSlice from "./Settings/settingsSlice";
import SiteSlice from "./Site/SiteSlice";
import smsSlice from "./Sms/smsSlice";
import spinLoadingState from "./SpinSlice/SpinSlice";
import staffSlice from "./Staff/staffSlice";
import carePlanSlice from "./carePlan/carePlanSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import episodeSlice from "./episode/episodeSlice";
import episodetmsSlice from "./episodetms/episodetmsSlice";
import formsSlice from "./forms/formsSlice";
import invoiceSlice from "./invoice/invoiceSlice";
import notificationSlice from "./notification/notificationSlice";
import patientSlice from "./patient/patientSlice";
import priceSlice from "./prices/priceSlice";
import profileTagsSlice from "./profileTagsSlice/profileTagsSlice";
import vitalSignsSlice from "./vitalSigns/vitalSignsSlice";

const rootReducer = combineReducers({
  auth: LoginSlice,
  staff: staffSlice,
  site: SiteSlice,
  profileTags: profileTagsSlice,
  spin: spinLoadingState,
  patient: patientSlice,
  form: formsSlice,
  carePlan: carePlanSlice,
  assessment: AssessmentSlice,
  common: commonSlice,
  reports: reportsSlice,
  vitalSign: vitalSignsSlice,
  episodetms: episodetmsSlice,
  alerts: alertSlice,
  notifications: notificationSlice,
  dashboardinfo: dashboardSlice,
  episode: episodeSlice,
  smsinfo: smsSlice,
  settings: settingsSlice,
  invoice: invoiceSlice,
  price: priceSlice,
});

export default rootReducer;
