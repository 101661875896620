import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const invoiceService = {
  createInvoice: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.INVOICE_API_URL, data),
  fetchInvoices: (params) =>
    Axiosinstance.get(API_URL_CONSTANTS.INVOICE_API_URL, { params }),
  getInvoice: (id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.INVOICE_API_URL}${id}`),
  updateInvoice: (id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.INVOICE_API_URL}${id}`, data),
  deleteInvoice: (id) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.INVOICE_API_URL}${id}`),
  fetchInvoiceList: (params) =>
    Axiosinstance.get(API_URL_CONSTANTS.INVOICE_LIST_API_URL, { params }),
  createInvoicePaymentLink: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.INVOICE_PAYMENT_LINK_API_URL, data),
  sendInvoice: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.INVOICE_SENT_API_URL, data),
};

export default invoiceService;
