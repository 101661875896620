import axios from "axios";
import CustomNotification from "../components/common/CustomNotification";
import { getDecryptedData } from "../utils/localStorage";
import { getidtoken, gettoken } from "../utils/token";
import eventTrackers from "../utils/trackEvent";

const { REACT_APP_API_BASE_URL } = process.env;
// Global flag to track notification display
let notificationDisplayed = false;
const Axiosinstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

Axiosinstance.interceptors.request.use((config) => {
  const val = localStorage.getItem("persist:root");
  const parsedVal = JSON.parse(val);
  const auth = parsedVal ? JSON.parse(parsedVal.auth) : {};
  const profileinfo = auth.profileinfo;

  const { authorization, id_token } = profileinfo || {};
  config.headers["x-auth-token"] = authorization || gettoken();
  config.headers["x-id-token"] = id_token || getidtoken();
  config.headers["x-trace-id"] = getDecryptedData("trace_id") || "";
  return config;
});

// Axiosinstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     console.error("error", error?.response?.status,error.response.data.message);

//     const errorMessages = {
//       400: error?.response?.data?.message || "Bad request",
//       401: error?.response?.data?.message || "Invalid username or password",
//       403: error?.response?.data?.message || "Forbidden",
//       404: error?.response?.data?.message || "Not found",
//       409: error?.response?.data?.message || "User already logged in",
//       422: error?.response?.data?.message || "Unprocessable entity",
//       500: error?.response?.data?.message || "Internal server error",
//       501: error?.response?.data?.message || "Not implemented",
//       502: error?.response?.data?.error?.message || "Bad gateway",
//       503: error?.response?.data?.error?.message || "Service unavailable",
//     };

//     const status = error?.response?.status;
//     const errorMessage = errorMessages[status] || error.message;

//     const config = error.config; // Define config here
//     let notificationDisplayed = false; // Add this variable to keep track of whether a notification has already been displayed

//     if (status === 401 && !notificationDisplayed) {
//       CustomNotification('error', 'Authentication Error', `${errorMessage}`);
//       notificationDisplayed = true; // Set the variable to true to indicate that a notification has been displayed
//     } else if (status === 0) {
//       // Handle CORS error
//       CustomNotification('error', 'CORS Error', "Unable to access the resource due to cross-origin restrictions.");
//     } else {
//       // Display the error message using Ant Design's message component
//       CustomNotification('error', 'Error', `${errorMessage}`);
//     }

//     return Promise.reject(error);
//   }
// );
Axiosinstance.interceptors.response.use(
  (response) => {
    // Reset notification flag on successful response
    notificationDisplayed = false;
    return response;
  },
  (error) => {
    console.error(
      "error",
      error?.response?.status,
      error?.response?.data?.message
    );

    const errorMessages = {
      400: "Bad request",
      401: "Invalid username or password",
      403: "Forbidden",
      404: "Not found",
      409: "User already logged in",
      422: "Unprocessable entity",
      500: "Internal server error",
      501: "Not implemented",
      502: "Bad gateway",
      503: "Service unavailable",
    };

    const status = error?.response?.status;
    const errorMessage =
      error?.response?.data?.message ||
      errorMessages[status] ||
      "An unexpected error occurred";

    // Log failed API request details
    if (error?.config) {
      const persistRoot = JSON.parse(
        localStorage.getItem("persist:root") || "{}"
      );
      const id = JSON.parse(persistRoot?.auth || "{}")?.userinfo?.id ?? "";

      const failedRequestDetails = {
        url: error.config.url,
        method: error.config.method,
        status,
        requestPayload: error.config.data,
      };

      const eventName = "API_FAILURE";

      eventTrackers?.trackEventerror({
        user_id: id,
        trace_id: undefined,
        event: eventName,
        endpoint: error.config.url,
        trackDetails: failedRequestDetails,
        description: `Failure regarding API: ${error.config.url}`,
        data: error.config.data,
      });
    }
    // Show CustomNotification only if not already displayed
    if (!notificationDisplayed && error.config.method !== "post") {
      CustomNotification("error", "Error", errorMessage);
      // Mark as displayed
      notificationDisplayed = true;

      // Optionally, add logic to reset `notificationDisplayed` flag
      // after a certain condition or timeout if needed
    } else {
      CustomNotification("error", "Error", errorMessage);
    }

    return Promise.reject(error);
  }
);
// Axiosinstance.interceptors.response.use(
//   (response) => {
//     // Reset the notification flag on successful response
//     notificationDisplayed = false;
//     return response;
//   },
//   async (error) => {
//     console.error("error", error?.response?.status, error.response.data.message);

//     const errorMessages = {
//       400: "Bad request",
//       401: "Invalid username or password",
//       403: "Forbidden",
//       404: "Not found",
//       409: "User already logged in",
//       422: "Unprocessable entity",
//       500: "Internal server error",
//       501: "Not implemented",
//       502: "Bad gateway",
//       503: "Service unavailable",
//       // Use the error response message if available, fallback to predefined messages
//     }.map((code) => error?.response?.data?.message || errorMessages[code]);

//     const status = error?.response?.status;
//     const errorMessage = errorMessages[status] || error.message;

//     // Only display notification if not already done
//     if (!notificationDisplayed) {
//       CustomNotification('error', 'Error', errorMessage);
//       notificationDisplayed = true; // Prevent further notifications

//       // Optional: Reset the flag after a timeout, so notifications can be shown again later
//       setTimeout(() => {
//         notificationDisplayed = false;
//       }, 30000); // Reset after 30 seconds
//     }

//     return Promise.reject(error);
//   }
// );

export default Axiosinstance;
