// Constants
import API_URL_CONSTANTS from "../constants/APIEndpoints";

// Axios instance
import Axiosinstance from "../config/config";

const smsTemplatesService = {
  // Get all SMS templates
  getAllTemplates: () =>
    Axiosinstance.get(API_URL_CONSTANTS.SMS_TEMPLATES_LIST_API_URL),

  // Create a new SMS template
  createTemplate: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.SMS_TEMPLATES_API_URL, data),
  updateTemplateInfo: (id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.SMS_TEMPLATES_API_URL}${id}`, data),
  deleteTemplateInfo: (id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.SMS_TEMPLATES_API_URL}${id}`, data),
  getSMSList: (patient_id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.SMS_LIST_API_URL}`, {
      params: {
        patient_id,
      },
    }),

  createSMS: (smsData) =>
    Axiosinstance.post(API_URL_CONSTANTS.SMS_API_URL, smsData),
  markSmsAsRead: (data) =>
    Axiosinstance.put(API_URL_CONSTANTS.SMS_MARK_AS_READ_API_URL, data),
};

export default smsTemplatesService;
