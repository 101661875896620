import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  const [isMixpanelInitialized, setIsMixpanelInitialized] = useState(false);

  useEffect(() => {
    try {
      mixpanel.init("20009d15fca6a8d06b23bcb3e6d94912", {
        debug: true, // Set to false in production
        ignore_dnt: true,
      });
      setIsMixpanelInitialized(true);
    } catch (error) {
      console.error("Failed to initialize Mixpanel:", error);
      Sentry.captureException(error);
    }
  }, []);

  const sessionStartTime = useRef(Date.now());
  const lastInteractionTime = useRef(Date.now());
  const interactionCount = useRef(0);

  const trackEvent = useCallback(
    (eventName, properties = {}) => {
      if (!isMixpanelInitialized) {
        console.warn("Mixpanel not initialized. Skipping event tracking.");
        return;
      }

      const now = Date.now();
      const enhancedProperties = {
        ...properties,
        timeSinceLastInteraction: now - lastInteractionTime.current,
        totalInteractionCount: ++interactionCount.current,
        timeSinceSessionStart: now - sessionStartTime.current,
      };

      try {
        mixpanel.track(eventName, enhancedProperties);

        if (["login", "signup", "purchase", "error"].includes(eventName)) {
          Sentry.captureMessage(eventName, {
            level: "info",
            extra: enhancedProperties,
          });
        }

        lastInteractionTime.current = now;
      } catch (error) {
        console.error("Failed to track event:", error);
        Sentry.captureException(error);
      }
    },
    [isMixpanelInitialized]
  );

  const trackPageView = useCallback(
    (path) => {
      if (isMixpanelInitialized) {
        try {
          mixpanel.track("Page View", { path });
        } catch (error) {
          console.error("Failed to track page view:", error);
          Sentry.captureException(error);
        }
      }

      Sentry.addBreadcrumb({
        category: "navigation",
        message: `Viewed ${path}`,
        level: "info",
      });
    },
    [isMixpanelInitialized]
  );

  const identifyUser = useCallback(
    (
      id,
      {
        firstName,
        lastName,
        email,
        plan = "free",
        signupDate = moment().format("YYYY-MM-DD"),
        ...additionalTraits
      }
    ) => {
      if (isMixpanelInitialized) {
        try {
          // Identify the user in Mixpanel
          mixpanel.identify(id);

          // Set user properties
          mixpanel.people.set({
            $first_name: firstName,
            $last_name: lastName,
            $email: email,
            $name: `${firstName} ${lastName}`.trim(),
            Plan: plan,
            "Sign Up Date": signupDate,
            ...additionalTraits,
          });

          // Set one-time properties (won't be overwritten)
          mixpanel.people.set_once({
            "First Seen": new Date().toISOString(),
            "Initial Plan": plan,
            "Initial Sign Up Date": signupDate,
          });

          // Track user identification as an event
          mixpanel.track("User Identified", {
            distinct_id: id,
            Plan: plan,
            "Sign Up Date": signupDate,
          });

          console.log("User identified in Mixpanel:", id);
        } catch (error) {
          console.error("Failed to identify user in Mixpanel:", error);
          Sentry.captureException(error);
        }
      } else {
        console.warn("Mixpanel not initialized. User identification skipped.");
      }

      // Set user in Sentry
      Sentry.setUser({
        id,
        email,
        username: `${firstName} ${lastName}`.trim(),
        plan,
        signUpDate: signupDate,
        ...additionalTraits,
      });
    },
    [isMixpanelInitialized]
  );

  return (
    <AnalyticsContext.Provider
      value={{ trackEvent, trackPageView, identifyUser }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAdvancedAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  return context;
};
