import { lazy } from "react";

const dashboard = lazy(() => import("./dashboard/dashboard"));
const MainDashboard = lazy(() => import("./dashboard/Maindashboard"));
const login = lazy(() => import("./authentication/loginPage"));
const resetPassword = lazy(() => import("./authentication/ResetPassword/"));
const assessmentPage = lazy(() => import("./quick-actions/assessmentPage"));
const InvoiceGeneratorPage = lazy(() =>
  import("./InvoiceGenerator/InvoiceGenerator")
);
const assessmentTrendReportpdf = lazy(() =>
  import(
    "./patients/assessmentTrendReport/components/Trendpdfviewer/AssesmentTrendReportpdf"
  )
);
const VitalTrendReportpdf = lazy(() =>
  import("./patients/vitalTrendReport/components/VitalTrendpdfviewer")
);
const BhiAssessmentPdfReport = lazy(() =>
  import(
    "./patients/bhIAssessmentReport/BhiAssessmentPdfReport/BhiAssessmentPdfReport"
  )
);
const assesmentQuestionnairesPage = lazy(() =>
  import(
    "./quick-actions/assessmentPage/assesmentQuestionnairesPage/assesmentQuestionnairesPage"
  )
);
const assignStaffPage = lazy(() => import("./quick-actions/assignStaffPage"));
const staffPage = lazy(() => import("./staff/staffPage"));

const addStaffPage = lazy(() => import("./staff/addStaffPage"));
const patientAssessmentpage = lazy(() =>
  import("./quick-actions/patientAssessmentDrawer/patientAssessmentDrawer")
);
const physicianBulkUploadpage = lazy(() =>
  import("./staff/physicianBulkUploadPage")
);
const dashboardWidgetspage = lazy(() => import("./staff/dashboardWidgets"));
const StaffViewPage = lazy(() => import("./staff/StaffViewPage"));
const assignMonitoringStaff = lazy(() =>
  import("./staff/StaffViewPage/assignMonitoringStaff/assignMonitoringStaff")
);
const reportsPage = lazy(() => import("./reports/reportsPage"));
const patientsPage = lazy(() => import("./patients/patientsPage"));
const patientViewPage = lazy(() =>
  import("./patients/patientViewPage/patientViewPage")
);
const addPatientPage = lazy(() => import("./patients/addPatientPage"));
const bulkAddPatientPage = lazy(() =>
  import("./patients/bulkAddPatientPage/bulkAddPatientPage")
);

const editPatientPage = lazy(() => import("./patients/editPatientPage"));
const vitalTrendPage = lazy(() => import("./patients/vitalTrendReport"));
const createEpisodePage = lazy(() => import("./patients/createPatientEpisode"));
const editEpisodePage = lazy(() => import("./patients/createPatientEpisode"));
const episodedetailsPage = lazy(() =>
  import("./patients/patientEpisodedetails")
);
const formEvalPage = lazy(() => import("./patients/formEvalPage"));
const carePlanAssignmentsPage = lazy(() =>
  import("./patients/carePlanAssignments")
);
const editAssignedCarePlanPage = lazy(() =>
  import("./patients/editAssignedCarePlanPage")
);
const patientAssignStaffPage = lazy(() =>
  import("./patients/patientAssignStaff")
);
// const episodesPage = lazy(() => import("./episodes/episodesPage"));
const assessmentTrendPage = lazy(() =>
  import("./patients/assessmentTrendReport")
);

const bhIAssessmentReportpage = lazy(() =>
  import("./patients/bhIAssessmentReport")
);
const PatientBulkTrendReportVitalPage = lazy(() =>
  import("./patients/patientBulkTrendReportVital")
);
const PatientBulkTrendReportAssessmentPage = lazy(() =>
  import("./patients/patientBulkTrendReportAssessment")
);
const BulkAssignMonitoringStaffPage = lazy(() =>
  import("./patients/bulkAssignMonitoringStaff")
);
const PatientBulkAddNotesPage = lazy(() =>
  import("./patients/bulkAssignMonitoringStaff")
);
// templates
const careplanPage = lazy(() => import("./templates/careplanPage"));
const activeCareplanPage = lazy(() =>
  import("./activeCarePlans/activeCarePlansPage")
);
const activecarePlanDetailsPage = lazy(() =>
  import("./activeCarePlans/activeCarePlansPage")
);
const addCareplanPage = lazy(() =>
  import("./templates/careplanPage/addCareplanPage/addCareplanPage")
);

const notePage = lazy(() => import("./templates/notePage"));
const noteTemplateFormPage = lazy(() =>
  import("./templates/notePage/noteTemplateFormPage/noteTemplateFormPage")
);
const notificationsPage = lazy(() =>
  import("../pages/notificationsPage/notificationsPage")
);

const smsPage = lazy(() => import("./templates/smsPage"));
const pricePage = lazy(() => import("./templates/prices/prices"));
// configure
const alertsPage = lazy(() => import("./configure/alertsPage"));
const diagnosisCodesPage = lazy(() => import("./configure/diagnosisCodesPage"));
const episodeBillingPage = lazy(() => import("./configure/episodeBillingPage"));
const formsPage = lazy(() => import("./configure/formsPage"));

const createFormPage = lazy(() =>
  import("./configure/formsPage/createFormPage/createFormPage")
);
const formPreviewPage = lazy(() =>
  import("./configure/formsPage/formPreviewPage/formPreviewPage")
);

const insurancePayersPage = lazy(() =>
  import("./configure/insurancePayersPage")
);
const bulkUploadPage = lazy(() =>
  import("./configure/insurancePayersPage/bulkUploadPage/bulkUploadPage")
);
const profileTagsPage = lazy(() => import("./configure/profileTagsPage"));
const addProfileTag = lazy(() =>
  import("./configure/profileTagsPage/addProfileTag/addProfileTag")
);
const settingsPage = lazy(() => import("./configure/settingsPage"));
const sitesPage = lazy(() => import("./configure/sitesPage"));
const editSitePage = lazy(() =>
  import("./configure/sitesPage/siteInfoPage/siteInfoPage")
);

const vitalSignsPage = lazy(() => import("./configure/vitalSignsPage"));
const vitalSignsViewPage = lazy(() =>
  import("./configure/vitalSignsPage/vitalSignsViewPage/vitalSignsViewPage")
);
const helpGuides = lazy(() => import("./helpGuides"));

// invoice page

const InvoicePreviewPage = lazy(() =>
  import("./InvoiceGenerator/components/InvoicePreview/InvoicePreview")
);
const InvoiceTablePage = lazy(() =>
  import("./InvoiceGenerator/components/InvoiceTable/InvoiceTable")
);
const InvoiceSenderpage = lazy(() =>
  import("./InvoiceGenerator/components/InvoiceSender/InvoiceSender")
);
const InvoiceFormpage = lazy(() =>
  import("./InvoiceGenerator/components/InvoiceForm/InvoiceForm")
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dashboard: MainDashboard, //dashboard
  login,
  resetPassword,
  StaffViewPage,
  addStaffPage,
  editEpisodePage,
  episodedetailsPage,
  formEvalPage,
  physicianBulkUploadpage,
  dashboardWidgetspage,
  addPatientPage,
  bulkAddPatientPage,
  editPatientPage,
  vitalTrendPage,
  assessmentTrendPage,
  createEpisodePage,
  patientAssignStaffPage,
  bhIAssessmentReportpage,
  PatientBulkTrendReportVitalPage,
  PatientBulkTrendReportAssessmentPage,
  BulkAssignMonitoringStaffPage,
  PatientBulkAddNotesPage,
  carePlanAssignmentsPage,
  editAssignedCarePlanPage,
  assessmentPage,
  assignStaffPage,
  staffPage,
  patientsPage,
  reportsPage,
  careplanPage,
  activeCareplanPage,
  addCareplanPage,
  notePage,
  noteTemplateFormPage,
  notificationsPage,
  smsPage,
  alertsPage,
  diagnosisCodesPage,
  episodeBillingPage,
  formsPage,
  createFormPage,
  formPreviewPage,
  insurancePayersPage,
  bulkUploadPage,
  profileTagsPage,
  addProfileTag,
  settingsPage,
  sitesPage,
  editSitePage,
  vitalSignsPage,
  vitalSignsViewPage,
  patientViewPage,
  assignMonitoringStaff,
  assesmentQuestionnairesPage,
  activecarePlanDetailsPage,
  helpGuides,
  patientAssessmentpage,
  assessmentTrendReportpdf,
  BhiAssessmentPdfReport,
  VitalTrendReportpdf,
  InvoiceGeneratorPage,
  InvoicePreviewPage,
  InvoiceTablePage,
  InvoiceSenderpage,
  InvoiceFormpage,
  pricePage,
};
