import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { useAdvancedAnalytics } from "../../hooks/AdvancedAnalyticsService";
import { DASHBOARD_PAGE_PATH } from "../PathConstants";

const PublicRoute = (props) => {
  const userinfo = useSelector((state) => state.auth?.profileinfo);
  const auth = userinfo?.authorization ? true : false;
  const { trackEvent } = useAdvancedAnalytics();

  useEffect(() => {
    trackEvent("Public Route Access", { authorized: auth });
  }, [auth, trackEvent]);

  return <>{auth ? <Navigate to={DASHBOARD_PAGE_PATH} /> : props.children}</>;
};

export default PublicRoute;
