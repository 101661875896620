// permissions.js
import { useSelector } from "react-redux";

export const usePermissions = () => {
  return useSelector((state) => state.auth?.userPermissions || {});
};

export const hasPermission = (permissions, permission) => {
  return !Boolean(permissions[permission]);
};
