import "./Container.scss";
// Add import for Header styles
import { Layout } from "antd";
import React, { Suspense, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGIN_PAGE_PATH } from "../../../route/PathConstants";
import { userLogOut } from "../../../store/Auth/AuthSlice";
// Import Header component
import CustomSpin from "../CustomSpin";
import ContainerHeader from "./components/Header/ContainerHeader";

const { Content, Sider } = Layout;

const Container = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleSider = () => {
    setCollapsed(!collapsed);
  };

  const handlerouter = (val) => {
    if (val) {
      navigate(LOGIN_PAGE_PATH);
    }
  };

  const handleClick = (e) => {
    if (e.key === "Logout") {
      dispatch(userLogOut({ handlerouter }));
    }
  };

  return (
    <div className="main-container">
      <Layout>
        {/* <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">
            <img
              src={require("../../../../src/assets/images/AvertiTextLogo.png")}
              alt="logo"
            />
            <img
              src={require("../../../../src/assets/images/AvertiTextLogo.png")}
              alt="logo"
            />
          </div>
         
        </Sider> */}
        <Layout className="site-layout">
          <Content className="site-layout-background">
            <ContainerHeader collapsed={collapsed} toggleSider={toggleSider} />
            <Suspense
              fallback={
                <CustomSpin
                  spinning={true}
                  color="var(--primary)"
                  size="large"
                />
              }
            >
              {props.children}
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default Container;
