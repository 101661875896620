import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const alertservice = {
  getAlerts: (is_active) =>
    Axiosinstance.get(API_URL_CONSTANTS.ALERT_CONFIG_LIST_API_URL, {
      params: { is_active },
    }),
  updateAlerts: (id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.ALERT_CONFIG_API_URL}${id}`, data),
};
export { alertservice };
