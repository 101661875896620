import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomNotification from "../../components/common/CustomNotification";
import invoiceService from "../../services/invoiceService";
import { setLoadingFalse, setLoadingTrue } from "../SpinSlice/SpinSlice";

export const createInvoice = createAsyncThunk(
  "invoice/create",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await invoiceService.createInvoice(data);
      console.log("response", response);
      thunkAPI.dispatch(setLoadingFalse());
      handlenavigate(response?.data);
      CustomNotification("success", "Success", "Invoice created successfully");
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchInvoices = createAsyncThunk(
  "invoice/fetchAll",
  async (params, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await invoiceService.fetchInvoices(params);
      thunkAPI.dispatch(setLoadingFalse());
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getInvoice = createAsyncThunk(
  "invoice/get",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await invoiceService.getInvoice(id);
      thunkAPI.dispatch(setLoadingFalse());
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "invoice/update",
  async ({ id, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await invoiceService.updateInvoice(id, data);
      thunkAPI.dispatch(setLoadingFalse());
      handlenavigate();
      CustomNotification("success", "Success", "Invoice updated successfully");
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteInvoice = createAsyncThunk(
  "invoice/delete",
  async (id, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await invoiceService.deleteInvoice(id);
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("success", "Success", "Invoice deleted successfully");
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchInvoiceList = createAsyncThunk(
  "invoice/fetchList",
  async (params, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await invoiceService.fetchInvoiceList(params);
      thunkAPI.dispatch(setLoadingFalse());
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // CustomNotification("error", "Error", "Failed to fetch invoice list");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const createInvoicePaymentLink = createAsyncThunk(
  "invoice/createPaymentLink",
  async ({ data, setIsPaymentLinkGenerated }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await invoiceService.createInvoicePaymentLink(data);
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification(
        "success",
        "Success",
        "Payment link created successfully"
      );
      setIsPaymentLinkGenerated();
      return response.data.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("error", "Error", "Failed to create payment link");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const sendInvoice = createAsyncThunk(
  "invoice/send",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await invoiceService.sendInvoice(data);
      thunkAPI.dispatch(setLoadingFalse());

      if (response && response.data) {
        handlenavigate();
        CustomNotification("success", "Success", "Invoice sent successfully");
        return response.data;
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());

      const errorMessage =
        error.response?.data?.message || "Failed to send invoice";
      // CustomNotification("error", "Error", errorMessage);

      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  newinvoice: {},
  invoiceList: [],
  paymentLink: {},
  invoice: null,
  loading: false,
  error: null,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    resetinvoiceDataState(state, action) {
      // Reset only the specified part of the state based on action.payload
      state[action.payload] = initialState[action.payload];
    },
    SetPaymentLinkDataState(state, action) {
      // Reset only the specified part of the state based on action.payload
      state.paymentLink = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInvoice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.newinvoice = action.payload;
      })
      .addCase(createInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchInvoiceList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInvoiceList.fulfilled, (state, action) => {
        state.loading = false;
        state.invoiceList = action.payload;
      })
      .addCase(fetchInvoiceList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createInvoicePaymentLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createInvoicePaymentLink.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentLink = action.payload;
      })
      .addCase(createInvoicePaymentLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    //   .addCase(fetchInvoices.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(fetchInvoices.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.invoices = action.payload;
    //   })
    //   .addCase(fetchInvoices.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   })
    //   .addCase(getInvoice.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(getInvoice.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.invoice = action.payload;
    //   })
    //   .addCase(getInvoice.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   })
    //   .addCase(updateInvoice.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(updateInvoice.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.invoice = action.payload;
    //   })
    //   .addCase(updateInvoice.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   })
    //   .addCase(deleteInvoice.pending, (state) => {
    //     state.loading = true;
    //     state.error = null;
    //   })
    //   .addCase(deleteInvoice.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.invoices = state.invoices.filter(
    //       (invoice) => invoice.id !== action.meta.arg
    //     );
    //   })
    //   .addCase(deleteInvoice.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   });
  },
});
export const { resetinvoiceDataState, SetPaymentLinkDataState } =
  invoiceSlice.actions;

export default invoiceSlice.reducer;
