import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const notificationservice = {
  getNotifications: (limit, offset, notification_status, priority_type) =>
    Axiosinstance.get(API_URL_CONSTANTS.NOTIFICATION_LIST_API_URL, {
      params: {
        limit,
        offset,
        notification_status,
        priority_type,
      },
    }),

  updateNotifications: (id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.NOTIFICATION_API_URL}/${id}`, data),
  getNotificationCount: () =>
    Axiosinstance.get(API_URL_CONSTANTS.NOTIFICATION_COUNT_API_URL),
  getPatientNotifications: (
    patientId,
    limit,
    offset,
    notification_status,
    priority_type,
    episode_type_id
  ) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.NOTIFICATION_PATIENT_API_URL}/${patientId}`,
      {
        params: {
          limit,
          offset,
          notification_status,
          priority_type,
          episode_type_id,
        },
      }
    ),
};

export { notificationservice };
