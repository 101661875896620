import { S3 } from "aws-sdk";
import axios from "axios";
import moment from "moment";
export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-", "#", "$", "(", "@", ")"].includes(e.key) &&
  e.preventDefault();
export const handlePlaceSelect =
  (form, fieldNames, onFormChange) => (value, option) => {
    // Use Google Places Details API to get complete place details
    const placeService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    placeService.getDetails({ placeId: option.key }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        // Extract country information
        let country = "";

        place.address_components.forEach((component) => {
          if (component?.types?.includes("country")) {
            country = component.long_name;
          }
        });

        // Extract other information as before
        let state = "";
        let city = "";
        let zipcode = "";

        place.address_components.forEach((component) => {
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          } else if (component.types.includes("locality")) {
            city = component.long_name;
          }
        });

        // Attempt to extract zipcode from formatted_address
        const formattedAddressComponents = place.formatted_address.split(",");
        if (formattedAddressComponents.length > 1) {
          const zipCodeString =
            formattedAddressComponents[
              formattedAddressComponents.length - 2
            ].trim();

          // Use a regular expression to extract only numeric characters
          zipcode = zipCodeString.replace(/\D/g, ""); // This removes all non-numeric characters
        }

        // Extract place name
        const placeName = place.name;

        // Extract address line 2 (assuming it's part of the formatted address)
        const addressComponents = place.formatted_address.split(",");
        const addressLine2 =
          addressComponents.length >= 2 ? addressComponents[1].trim() : "";

        // Update the form values with the selected place details, including the country
        form.setFieldsValue({
          [fieldNames.address]: placeName,
          [fieldNames.address1]: addressLine2,
          [fieldNames.zipcode]: zipcode,
          [fieldNames?.country]: country, // Set the extracted country value
          [fieldNames.state]: state,
          [fieldNames.city]: city,
        });

        if (zipcode) {
          onFormChange();
        }
      }
    });
  };

export const handlePlaceSearch = (setOptions) => (value) => {
  if (value) {
    // Use Google Places Autocomplete API to get predictions
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: value }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setOptions(predictions);
      }
    });
  } else {
    setOptions([]);
  }
};
export const formatPhoneNumber = (input) => {
  // Remove any non-digit characters
  const cleaned = ("" + input)?.replace(/\D/g, "");

  // Check if the cleaned input is a valid US phone number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Format the phone number as (XXX) XXX-XXXX
    // return `(${match[1]}) ${match[2]}-${match[3]}`;
    return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
  }

  // If not a valid phone number, return the original input
  return input;
};
export const formatInputPhoneNumber = (input) => {
  // Remove any non-digit characters
  const cleaned = ("" + input).replace(/\D/g, "");

  // Check if the cleaned input is a valid 10-digit number
  if (/^\d{10}$/.test(cleaned)) {
    // Format the phone number as +1 (XXX) XXX-XXXX
    return `(${cleaned.substring(0, 3)}) ${cleaned.substring(
      3,
      6
    )}-${cleaned.substring(6)}`;
  }

  // If not a valid 10-digit number, return the original input
  return input;
};

export const calculateAge = (dateOfBirth) => {
  if (!dateOfBirth) return null;

  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age.toString(); // Convert age to a string for input field
};
export const calculateAndFormatAge = (dateOfBirth) => {
  if (!dateOfBirth) return "";

  const today = new Date();
  const birthDate = new Date(dateOfBirth);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age > 0 ? `${age} ${age > 1 ? "Years" : "Year"}` : "";
};

export const formatAddress = (details) => {
  const addressLine1Parts = (
    details?.contact_address ||
    details?.contact_address_line1 ||
    ""
  )
    .split(",")
    .map((part) => part.trim());
  const addressLine2Parts = (
    details?.contact_address
      ? details?.contact_address_line1
      : details?.contact_address_line2 || ""
  )
    .split(",")
    .map((part) => part.trim());

  // Include additional address parts
  const additionalParts = [
    details?.contact_city,
    details?.contact_state,
    details?.contact_country,
    details?.contact_zip_code,
  ].filter(Boolean);

  // Filter out address parts from contact_address_line2 that already exist in contact_address_line1
  const uniqueAddressLine2Parts = addressLine2Parts.filter(
    (part) => !addressLine1Parts.includes(part)
  );

  // Filter out additional address parts that already exist in contact_address_line1 or contact_address_line2
  const uniqueAdditionalParts = additionalParts.filter((part) => {
    return (
      !addressLine1Parts.includes(part) && !addressLine2Parts.includes(part)
    );
  });

  // Join all unique address parts
  const formattedAddress = [
    addressLine1Parts.join(""), // Join addressLine1Parts without delimiter
    uniqueAddressLine2Parts.join(","), // Join uniqueAddressLine2Parts with comma and space
    uniqueAdditionalParts.join(","), // Join uniqueAdditionalParts with comma and space
  ].join(","); // Join all parts without any delimiter

  return formattedAddress;
};

export const disablefuturedDate = (current) => {
  // Get the current date
  const today = new Date();

  // Allow only past and today's dates
  return current && current > today;
};
// Define the trim function to remove all spaces
export const trimValue = (value) => {
  // Check if the value is a string
  if (typeof value === "string") {
    // Remove all spaces using a regular expression
    return value.replace(/\s/g, "");
  }
  // Return the original value if it's not a string
  return value;
};

// TrimUtil.js

export const trimObjectStringProperties = (data) => {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      typeof value === "string" ? value.trim() : value,
    ])
  );
};

export const trimString = (str) => {
  return str?.trim() || "";
};

export const formatAgeString = (age) => {
  return age > 0 ? `${age} ${age > 1 ? "Years" : "Year"}` : "Not Available";
};
export const formatDate = (dateString) => {
  const momentDate = moment.utc(dateString, moment.ISO_8601);
  if (momentDate.isValid()) {
    return momentDate.local().format("MMM D, YYYY");
  } else {
    return "-"; // You can change this message to whatever you prefer
  }
};

export const formatDateTime = (dateTimeString) => {
  return moment
    .utc(dateTimeString, moment.ISO_8601)
    .local()
    .format("MMM D, YYYY, h:mm:ss A");
};
export const formatDateWithYears = (date) => {
  const parsedDate = moment(date);
  const years = moment().diff(parsedDate, "years");
  const yearLabel = years === 1 ? "year" : "years";
  return `${parsedDate.format("MMM DD, YYYY")} (${years} ${yearLabel})`;
};

export const localStorageHelper = {
  set: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
  get: (key) => JSON.parse(localStorage.getItem(key)),
  remove: (key) => localStorage.removeItem(key),
};
export const fetchICDCodes = async (authenticityToken) => {
  try {
    const apiUrl = `https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&df=code,name&authenticity_token=${authenticityToken}&terms=`;
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.error("Error fetching ICD codes:", error);
    throw error;
  }
};

export const setDynamicFavicon = (faviconUrl) => {
  const faviconLink =
    document.querySelector("link[rel~='icon']") ||
    document.createElement("link");
  faviconLink.rel = "icon";
  faviconLink.href = faviconUrl;

  document.head.appendChild(faviconLink);

  return () => document.head.removeChild(faviconLink);
};

export const buildEventPath = (...pathParts) => pathParts.join(".");

export const uploadFileToS3 = async (blob, fileName) => {
  const s3 = new S3({
    bucketName: process.env.REACT_APP_API_BUCKET_NAME,
    // dirName: hostid || email /* optional */,
    region: process.env.REACT_APP_API_REGION_NAME,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const params = {
    Bucket: process.env.REACT_APP_API_BUCKET_NAME,
    Key: `${fileName}.pdf`,
    Body: blob,
  };

  const data = await s3.upload(params).promise();

  return data.Location;
};

export const cityToStateMap = {
  "New York": "NY",
  "Los Angeles": "CA",
  Chicago: "IL",
  Houston: "TX",
  Phoenix: "AZ",
  Philadelphia: "PA",
  "San Antonio": "TX",
  "San Diego": "CA",
  Dallas: "TX",
  "San Jose": "CA",
  // Add more cities and their corresponding state abbreviations as needed
};
const loadGoogleMapsAPI = (callback) => {
  const existingScript = document.getElementById("google-maps-script");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD4kyAD0y65Vu3Lvj-5VFLnJbYVpr_WYz0&libraries=places`;
    script.id = "google-maps-script";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) {
    callback();
  }
};

export default loadGoogleMapsAPI;
