import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const episodeTms = {
  getepisodeTmslogsAcitivityList: () =>
    Axiosinstance.get(`${API_URL_CONSTANTS.TMS_LOG_API_URL}/activity/all`, {
      //   params: {
      //     limit,
      //     offset,
      //     search_text,
      //   },
    }),
  createEpisodeTms: (data) =>
    Axiosinstance.post(`${API_URL_CONSTANTS.TMS_LOG_API_URL}/`, data),

  getepisodeTmsinfo: (id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.TMS_LOG_API_URL}/all/${id}`),
  deleteEpisodeTmsInfo: (id) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.TMS_LOG_API_URL}/${id}`),
  updateEpisodeTmsInfo: (id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.TMS_LOG_API_URL}/${id}`, data),
};
export { episodeTms };
