import { createSlice } from "@reduxjs/toolkit";

const SpinLoadingState = createSlice({
  name: "roles",
  initialState: {
    loading: false,
    TableLoading: false,
    ButtonLoading: true,
  },
  reducers: {
    setLoadingTrue(state) {
      state.loading = true;
      state.TableLoading = false;
    },
    setLoadingFalse(state) {
      state.loading = false;
      state.TableLoading = false;
    },
    setTableLoadingTrue(state) {
      state.loading = false;
      state.TableLoading = true;
    },
    setTableLoadingFalse(state) {
      state.loading = false;
      state.TableLoading = false;
    },
    setButtonLoadingFalse(state) {
      state.ButtonLoading = false;
    },
    setButtonLoadingTrue(state) {
      state.ButtonLoading = true;
    },
  },
});
export const {
  setLoadingFalse,
  setLoadingTrue,
  setTableLoadingTrue,
  setTableLoadingFalse,
  setButtonLoadingFalse,
  setButtonLoadingTrue,
} = SpinLoadingState.actions;
export default SpinLoadingState.reducer;
