import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "antd/dist/antd.min.css";
import "antd/dist/antd.variable.min.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import ConfigProviderComponent from "./assets/theme/ConfigProvider";
import i18n from "./config/i18n";

import { AnalyticsProvider } from "./hooks/AdvancedAnalyticsService";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./store/store";

Sentry.init({
  dsn: "https://8301125aef355d9a4447c23a7cb0d7ea@us.sentry.io/4506700544737280",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

if (typeof window !== "undefined") {
  window.addEventListener("storage", () => {
    if (localStorage.getItem("persist:root") === null) {
      window.location.reload(true);
    }
  });
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <ConfigProviderComponent>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AnalyticsProvider>
                {/* <Router> */}
                <App />
                {/* </Router> */}
              </AnalyticsProvider>
            </PersistGate>
          </Provider>
        </I18nextProvider>
      </ConfigProviderComponent>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});
