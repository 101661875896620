import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const priceService = {
  getPriceTemplates: () =>
    Axiosinstance.get(API_URL_CONSTANTS.PRICE_TEMPLATES_API_URL),
  updatePriceTemplate: (data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.PRICE_TEMPLATES_API_URL}`, data),
};

export default priceService;
