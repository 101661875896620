import React from "react";
import { Drawer as AntdDrawer, Divider } from "antd";
// import './CustomDrawer.scss';
// Import your SCSS styles

const CustomDrawer = ({
  className,
  open,
  onClose,
  title,
  children,
  ...restProps
}) => {
  const drawerClassName = `custom-drawer ${className || ""}`;

  return (
    <AntdDrawer
      title={title}
      placement="right"
      onClose={onClose}
      open={open}
      className={drawerClassName}
      {...restProps}
    >
      {children}
    </AntdDrawer>
  );
};

export default CustomDrawer;
