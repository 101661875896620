// CustomNotification.js
import "./CustomNotification.scss";
import { notification } from "antd";

const CustomNotification = (type, message, description, duration) => {
  notification[type]({
    message,
    description,
    placement: "bottomRight", // Set the placement to bottom right
    // duration: duration
    // duration: 600,
  });
};

export default CustomNotification;
