import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";

const assessment = {
  // Organizational form-related functions

  getAssessment: (formId) =>
    Axiosinstance.get(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_CAREPLAN_INTERVENTION_FORM_API_URL}${formId}`
    ),

  submitAssessment: (id, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.PATIENT_EPISODE_CAREPLAN_INTERVENTION_FORM_API_URL}${id}`,
      data
    ),
  //  PATIENT_NOTES_API_URL
  getNotes: (Id) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.PATIENT_NOTES_API_URL}/${Id}`),
  submitNotes: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.PATIENT_NOTES_API_URL, data),
  updateNotes: (Id, data) =>
    Axiosinstance.put(`${API_URL_CONSTANTS.PATIENT_NOTES_API_URL}/${Id}`, data),
};

export { assessment };
