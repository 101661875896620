import { getMessaging, onMessage } from "firebase/messaging";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import CustomSpin from "./components/common/CustomSpin";
import { buildVersion, updateRoleIds } from "./constants/AppConstants";
import { firebase, initNotifications } from "./firebase";
import AppRoute from "./route/AppRoute";

import moment from "moment";
import { useAdvancedAnalytics } from "./hooks/AdvancedAnalyticsService";
import { fetchNotificationCount } from "./store/notification/notificationSlice";

function App() {
  const [loadingSpin, setLoadingSpin] = useState(false);
  const loadingState = useSelector((state) => state?.spin?.loading);
  const [notificationCount, setNotificationCount] = useState(0);
  const [location, setLocation] = useState(null);
  const [messaging, setMessaging] = useState(null);
  const dispatch = useDispatch();
  const { trackEvent, trackPageView, identifyUser } = useAdvancedAnalytics();
  const {
    firstname: firstName,
    lastname: lastName,
    id,
    email,
  } = useSelector((state) => state?.auth?.userinfo) ?? {};
  const val = localStorage.getItem("persist:root");
  const parsedVal = val ? JSON.parse(val) : "";
  const auth = parsedVal ? JSON.parse(parsedVal.auth) : {};
  const profileinfo = auth.profileinfo;

  const { authorization } = profileinfo || {};
  console.log("profileinfo", profileinfo);
  useEffect(() => {
    if (authorization) {
      const setupMessaging = async () => {
        try {
          await initNotifications();
          const messagingInstance = getMessaging(firebase);
          setMessaging(messagingInstance);
          trackEvent("Messaging Setup", { success: true });
        } catch (error) {
          console.error("Error setting up messaging:", error);
          trackEvent("Messaging Setup Error", { error: error.message });
        }
      };
      updateRoleIds();
      trackEvent("User Authorized");
      identifyUser(id, {
        firstName,
        lastName,
        email,
        // plan,
        signupDate: moment().format("YYYY-MM-DD"),
        userType: "new",
        source: "web_signup",
        preferredLanguage: navigator.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      setupMessaging();
    }
  }, [authorization, trackEvent]);

  useEffect(() => {
    if (messaging) {
      const handleForegroundMessage = (payload) => {
        setNotificationCount((prevCount) => prevCount + 1);
        dispatch(fetchNotificationCount());
        trackEvent("Notification Received", { type: payload.type });
      };

      onMessage(messaging, handleForegroundMessage);
    }
  }, [messaging, dispatch, trackEvent]);

  useEffect(() => {
    setLoadingSpin(loadingState);
    const ID = setTimeout(() => {
      setLoadingSpin(false);
    }, 4000);
    return () => {
      clearTimeout(ID);
    };
  }, [loadingSpin, loadingState]);

  useEffect(() => {
    const captureGeolocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            trackEvent("Geolocation Captured", { latitude, longitude });
          },
          (error) => {
            console.error("Error capturing location:", error);
            setLocation("Geolocation not permitted");
            trackEvent("Geolocation Error", { error: error.message });
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setLocation("Geolocation not supported");
        trackEvent("Geolocation Not Supported");
      }
    };

    captureGeolocation();
  }, [trackEvent]);

  return (
    <div className="app-container">
      <CustomSpin spinning={loadingSpin} color="var(--primary)" size="large" />
      <Suspense
        fallback={
          <CustomSpin
            spinning={loadingSpin}
            color="var(--primary)"
            size="large"
          />
        }
      >
        <Router>
          <AppRoute />
        </Router>
      </Suspense>
      <div className="app-version">
        <span>Rpm-V : {buildVersion}</span>
      </div>
    </div>
  );
}

export default App;
