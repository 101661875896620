import axios from "axios";
import Axiosinstance from "../config/config";
import API_URL_CONSTANTS from "../constants/APIEndpoints";
const { REACT_APP_API_BASE_URL } = process.env;
const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL, // Replace with your actual base URL
});
const common = {
  userSignIn: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.LOGIN_API_URL, data),
  userSignOut: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.LOGOUT_API_URL, data),
  getRefreshToken: () =>
    api.get(API_URL_CONSTANTS.AUTH_REFRESH_API_URL, {
      headers: {
        "x-refresh-token": JSON.parse(
          JSON.parse(localStorage.getItem("persist:root")).auth
        )?.profileinfo.refresh,
      },
    }),
  resetPassword: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.RESET_PASSWORD_API_URL, data),
  getProfileInfo: () =>
    Axiosinstance.get(API_URL_CONSTANTS.STAFF_USER_PROFILE_API_URL),
  getRoleList: () =>
    Axiosinstance.get(API_URL_CONSTANTS.PRIVILEGES_ROLES_API_URL),
  getUserPermissions: () =>
    Axiosinstance.get(API_URL_CONSTANTS.STAFF_USER_PERMISSIONS_API_URL),
  updatePassword: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.PASSWORD_RESET_API_URL, data),
};
export { common };
