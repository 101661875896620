import "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Axiosinstance from "./config/config";

const firebaseConfig = {
  apiKey: "AIzaSyBmJA7v_okproF4cQCOkWB8zATGjvb8Lbs",
  authDomain: "dev-rpm-pylo.firebaseapp.com",
  projectId: "dev-rpm-pylo",
  storageBucket: "dev-rpm-pylo.appspot.com",
  messagingSenderId: "856880931234",
  appId: "1:856880931234:web:576f0dde7950d84acd52fb",
  measurementId: "G-WZKLLB9BJE",
};

const firebase = initializeApp(firebaseConfig);

// Request notification permission and get the token
const initNotifications = async () => {
  try {
    const messaging = getMessaging(firebase);
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging);

      await saveTokenToServer(token);
      // If you want to perform analytics, you can enable it here
      // analytics.logEvent('notifications_enabled');
    } else {
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};

// Save the token to your server
const saveTokenToServer = async (token) => {
  try {
    const response = await Axiosinstance.post("/notification/token/", {
      token,
    });
    if (response.status !== 200) {
      throw new Error("Failed to save notification token");
    }
  } catch (error) {
    console.error("Error saving notification token:", error);
  }
};

// Export the initialized firebase instance and the initNotifications function
export { firebase, initNotifications };
