import React from "react";
import { Tooltip } from "antd";
import "./CustomTooltip.scss"; // Import your SCSS styles

const CustomTooltip = ({ title, content, className, ...restProps }) => {
  const tooltipClassName = `custom-tooltip ${className || ""}`;

  return (
    <Tooltip
      title={title}
      className={tooltipClassName}
      {...restProps}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {content}
    </Tooltip>
  );
};

export default CustomTooltip;
