// Authentication
export const LOGIN_PAGE_PATH = "/login";
export const SIGNUP_PAGE_PATH = "/signup";
export const RESET_PASSWORD_PAGE_PATH = "/resetpassword";

// export const ENTER_OTP_PAGE_PATH = "/enterotp";
export const ENTER_OTP_FIELDS_PATH = "/enterotp";
export const UPDATE_PASSWORD_PAGE_PATH = "/settings/password-reset";
export const CONFIRM_REGISTER_PAGE_PATH = "/confirmregister";

export const DASHBOARD_PAGE_PATH = "/dashboard";
export const ASSESSMENT_PAGE_PATH = "/assessment";
export const ASSESSMENT_QUESTIONNAIRES_PAGE_PATH = "/assessment/questionnaires";
export const ASSIGN_STAFF_PAGE_PATH = "/assign-staff";
export const USER_PAGE_PATH = "/user";
export const STAFF_PAGE_PATH = "/staff";
export const ADD_STAFF_BASE_PATH = "/staff/add";
export const EDIT_STAFF_BASE_PATH = "/staff/edit";
export const PHYSICIAN_BULK_UPLOAD_PATH = "/staff/physician/bulk-upload";
export const STAFF_VIEW_WIDGETS_PATH = "/staff/view/dashboard-widgets"; // New path
export const ASSIGN_MONITORING_STAFF_PATH = "/staff/view/assign-staff/:id";

export const PATIENTS_PAGE_PATH = "/patients";
export const PATIENT_EDIT_BASE_PATH = "/patient/edit";
export const PATIENT_VIEW_BASE_PATH = "/patient/view";
export const PATIENT_VITAL_TREND_BASE_PATH = "/patient/vital-trend/:id";
export const PATIENT_ASSESSMENT_TREND_BASE_PATH =
  "/patient/assessment-trend/:id";
export const ASSESSMENT_TREND_REPORT_PDF_PATH =
  "/patient/assessment-trend/report";
export const VITAL_TREND_REPORT_PDF_PATH = "/patient/vital-trend/report";
export const PATIENT_ASSESSMENT_REPORT_BASE_PATH =
  "/patient/assessment-report/:id";

export const PATIENT_ASSESSMENT_REPORT_BASE_PATHS = "assessment-report";

export const ANALYTICS_BHI_ASSESSMENT_REPORT_BASE_PATH =
  "/analytics/bhi-assessment-report/:id";
export const ANALYTICS_PDF_BHI_ASSESSMENT_REPORT_BASE_PATH =
  "/patient/bhi-assessment-report/report";
export const PATIENT_BULK_TREND_REPORT_VITAL_PATH =
  "/patient/bulk-trend-report/vital";
export const PATIENT_BULK_TREND_REPORT_ASSESSMENT_PATH =
  "/patient/bulk-trend-report/assessment";
export const BULK_ASSIGN_MONITORING_STAFF_PATH =
  "/bulk/assign/monitoring-staff";
export const PATIENT_BULK_ADD_NOTES_PATH = "/patient/bulk-add-notes";

export const EPISODES_FORM_ADD_PATH = "/episodes/form/add";
export const EPISODES_FORM_EDIT_BASE_PATH = "/episodes/form/edit/:id";
export const EPISODES_DETAILS_BASE_PATH = "/episodes/details/:id";
export const PATIENT_ASSIGN_STAFF_BASE_PATH = "/patient/assign-staff";
export const EVAL_FORMS_VIEW_BASE_PATH = "/form/evalforms/view";
export const CARE_PLAN_ASSIGNMENTS_BASE_PATH = "/careplanassignments/patient";
export const EDIT_ASSIGNED_CAREPLAN_ROUTE = "/course/edit-assigned-careplan";

export const ACTIVE_CARE_PLANS_PAGE_PATH = "/active-care-plans";
export const ACTIVE_CARE_PLANS_DETAILS_PAGE_PATH = "/active-care-plans/details";
export const REPORTS_PAGE_PATH = "/reports";
export const BILLING_PAGE_PATH = "/billing";
export const SETTINGS_PAGE_PATH = "/settings";
export const SITES_PAGE_PATH = "/sites";
export const EDIT_SITE_PAGE_PATH = "/sites/edit";
export const ADD_SITE_PAGE_PATH = "/sites/add";
export const VITAL_SIGNS_PAGE_PATH = "/vital-signs";
export const VITAL_SIGNS_VIEW_PAGE_PATH = "/vital-signs/view";
export const FORMS_PAGE_PATH = "/forms";
export const FORMS_PREVIEW_PAGE_PATH = "/forms/preview";
export const CREATE_FORM_PAGE_PATH = "/forms/Create";
export const ALERTS_PAGE_PATH = "/alerts";
export const PROFILE_TAGS_PAGE_PATH = "/profile-tags";
export const ADD_PROFILE_PAG_PAGE_PATH = "/profile-tags/add";
export const EDIT_PROFILE_PAG_PAGE_PATH = "/profile-tags/edit";
export const INSURANCE_PAYERS_PAGE_PATH = "/insurance-payers";
export const BULK_UPLOAD_PAGE_PATH = "/insurance-payers/bulk-upload";
export const DIAGNOSIS_CODES_PAGE_PATH = "/diagnosis-codes";
export const EPISODE_BILLING_PAGE_PATH = "/episode-billing";
export const CAREPLAN_PAGE_PATH = "/careplan";
export const ADD_CAREPLAN_PAGE_PATH = "/careplan/add";
export const EDIT_CAREPLAN_PAGE_PATH = "/careplan/edit";
export const ASSIGN_CAREPLAN_PAGE_PATH = "/careplan/assign";
export const SMS_PAGE_PATH = "/sms";
export const NOTE_PAGE_PATH = "/note";
export const NOTE_TEMPLATE_FORM_PAGE_PATH = "/note/form";
export const NOTIFICATIONS_PAGE_PATH = "/notifications";
export const DOCUMENTATION_PAGE_PATH = "/documentation";
export const TUTORIALS_PAGE_PATH = "/tutorials";

export const INVOICE_GENERATOR_PATH = "/invoice-generator";

///patient/episode/careplan/{episode_id}
export const PATIENT_EPISODE_CAREPLAN_PAGE_PATH = "/patient/episode/careplan";

// Add more path constants as needed

export const STAFF_VIEW_PAGE_PATH = "/staff/view/:id";
export const ADD_PATIENT_PAGE_PATH = "/patient/add";
export const BULK_ADD_PATIENT_PAGE_PATH = "/patient/bulk-add";
export const EDIT_PATIENT_PAGE_PATH = "/patient/edit/:patientId";
export const PATIENT_VIEW_PAGE_PATH = "/patient/view/:patientId";
export const HELP_GUIDES_PAGE_PATH = "/help-guides";
export const PRICES_PAGE_PATH = "/prices";

export const INVOICE_OVER_VIEW_PATH = "/invoice/overview";
export const INVOICE_FORM_VIEW_PATH = "/invoice/form";
export const INVOICE_PREVIEW_VIEW_PATH = "/invoice/preview";
export const INVOICE_SENDER_VIEW_PATH = "/invoice/sender";
