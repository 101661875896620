import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import CustomNotification from "../../components/common/CustomNotification";
import carePlan from "../../services/carePlan";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

// Create a new carePlan
export const createCarePlan = createAsyncThunk(
  "create/carePlan",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await carePlan.createCarePlan(data);

      if (response.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        if (response?.data.status) {
          handlenavigate(response?.data.status);
          CustomNotification(
            "success",
            "Success",
            '"Sucessfully carePlan added"'
          );

          return response?.data;
        }
      } else {
        thunkAPI.dispatch(setLoadingFalse());
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// Fetch a list of carePlans
export const fetchCarePlanList = createAsyncThunk(
  "fetch/carePlanList",
  async (params, thunkAPI) => {
    const { limit, offset, search_text } = params;
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await carePlan.getCarePlanList(
        limit,
        offset,
        search_text
      );
      if (response && response.status) {
        thunkAPI.dispatch(setLoadingFalse());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// Update carePlan information
export const updateCarePlanInfo = createAsyncThunk(
  "fetch/updateCarePlanInfo",
  async ({ CareplanId, data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await carePlan.updateCarePlanDetails(CareplanId, data);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification(
          "success",
          "Success",
          '"Sucessfully carePlan Updated"'
        );
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

// Fetch carePlan information
export const fetchCarePlanInfo = createAsyncThunk(
  "fetch/fetchCarePlanInfo",
  async ({ itemId, handlerouter }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await carePlan.getCarePlanDetails(itemId);

      if (response?.data.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlerouter();
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

// Remove carePlan information
export const removeCarePlanInfo = createAsyncThunk(
  "fetch/removeCarePlanInfo",
  async ({ currentCarePlan, fetchData }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await carePlan.deleteCarePlan(currentCarePlan);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        fetchData();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

const carePlanSlice = createSlice({
  name: "carePlan",
  initialState: {
    carePlanList: [],
    carePlanInfo: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCarePlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCarePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.carePlanInfo = action.payload;
      })
      .addCase(createCarePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCarePlanList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCarePlanList.fulfilled, (state, action) => {
        state.loading = false;
        state.carePlanList = action.payload;
      })
      .addCase(fetchCarePlanList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateCarePlanInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCarePlanInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.carePlanInfo = action.payload;
      })
      .addCase(updateCarePlanInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCarePlanInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCarePlanInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.carePlanInfo = action.payload;
      })
      .addCase(fetchCarePlanInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeCarePlanInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCarePlanInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.carePlanInfo = action.payload;
      })
      .addCase(removeCarePlanInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default carePlanSlice.reducer;
