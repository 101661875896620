import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomNotification from "../../components/common/CustomNotification";
import { orgvitalSigns } from "../../services/orgvitalSigns";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

// Async thunk to fetch all vital signs
export const fetchAllVitalSigns = createAsyncThunk(
  "org/fetchAllVitalSigns",
  async ({ limit, offset }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await orgvitalSigns.getAllOrgvitalSigns(limit, offset);

      if (response?.status) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
      // Return the response data
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return thunkAPI.rejectWithValue(error); // Rejecting with value to trigger the `rejected` action
    }
  }
);

// Async thunk to create a new vital sign entry
export const createVitalSignEntry = createAsyncThunk(
  "org/createVitalSignEntry",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await orgvitalSigns.createOrgvitalSigns(data);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
  }
);

// Async thunk to update a vital sign entry by ID
export const updateVitalSignEntry = createAsyncThunk(
  "org/updateVitalSignEntry",
  async ({ id, vitalData, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await orgvitalSigns.updateOrgvitalSigns(id, vitalData);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
  }
);

// Async thunk to get a vital sign entry by ID
export const getVitalSignById = createAsyncThunk(
  "org/getVitalSignById",
  async ({ id, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await orgvitalSigns.getOrgVitalSignById(id);
      if (response?.data.id) {
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
  }
);

// Redux slice
const orgVitalSignsSlice = createSlice({
  name: "orgVitalSigns",
  initialState: {
    vitalSigns: [],
    vitalSignsinfo: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllVitalSigns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllVitalSigns.fulfilled, (state, action) => {
        state.loading = false;
        state.vitalSigns = action.payload;
      })
      .addCase(fetchAllVitalSigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createVitalSignEntry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createVitalSignEntry.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createVitalSignEntry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateVitalSignEntry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateVitalSignEntry.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateVitalSignEntry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getVitalSignById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getVitalSignById.fulfilled, (state, action) => {
        state.vitalSignsinfo = action.payload;
        state.loading = false;
      })
      .addCase(getVitalSignById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default orgVitalSignsSlice.reducer;
