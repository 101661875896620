import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomNotification from "../../components/common/CustomNotification";
import priceService from "../../services/priceService";
import { setLoadingFalse, setLoadingTrue } from "../SpinSlice/SpinSlice";

export const fetchPriceTemplates = createAsyncThunk(
  "price/fetchPriceTemplates",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await priceService.getPriceTemplates();
      thunkAPI.dispatch(setLoadingFalse());
      //   CustomNotification(
      //     "success",
      //     "Success",
      //     "Price templates fetched successfully"
      //   );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("error", "Error", "Failed to fetch price templates");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const updatePriceTemplate = createAsyncThunk(
  "price/updatePriceTemplate",
  async ({ data, hadlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await priceService.updatePriceTemplate(data);
      thunkAPI.dispatch(setLoadingFalse());
      hadlenavigate();
      CustomNotification(
        "success",
        "Success",
        "Price template updated successfully"
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      // CustomNotification("error", "Error", "Failed to update price template");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  priceTemplates: null,
  loading: false,
  error: null,
};

const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPriceTemplates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPriceTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.priceTemplates = action.payload;
      })
      .addCase(fetchPriceTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default priceSlice.reducer;
