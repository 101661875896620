// Constants
import API_URL_CONSTANTS from "../constants/APIEndpoints";

// Axios instance
import Axiosinstance from "../config/config";

const carePlan = {
  // Retrieve a list of care plans for the organization
  getCarePlanList: (limit, offset, search_text, status) =>
    Axiosinstance.get(API_URL_CONSTANTS.GET_ALL_CARE_PLAN_API_URL, {
      params: {
        limit,
        offset,
        search_text,
        status,
      },
    }),

  // Create a new care plan for the organization
  createCarePlan: (data) =>
    Axiosinstance.post(API_URL_CONSTANTS.CARE_PLAN_API_URL, data),

  // Retrieve details of a specific care plan by ID
  getCarePlanDetails: (carePlanId) =>
    Axiosinstance.get(`${API_URL_CONSTANTS.CARE_PLAN_API_URL}${carePlanId}`),

  // Update details of a specific care plan by ID
  updateCarePlanDetails: (CareplanId, data) =>
    Axiosinstance.put(
      `${API_URL_CONSTANTS.CARE_PLAN_API_URL}${CareplanId}`,
      data
    ),

  // Delete a specific care plan by ID
  deleteCarePlan: (carePlanId) =>
    Axiosinstance.delete(`${API_URL_CONSTANTS.CARE_PLAN_API_URL}${carePlanId}`),
};

export default carePlan;
