import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomNotification from "../../components/common/CustomNotification";
import { orgforms } from "../../services/form";
import {
  setButtonLoadingFalse,
  setButtonLoadingTrue,
  setLoadingFalse,
  setLoadingTrue,
} from "../SpinSlice/SpinSlice";

export const fetchFormData = createAsyncThunk(
  "fetch/formData",
  async (params, thunkAPI) => {
    const { limit, offset, search_text, fetchdata } = params ?? {};
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await orgforms.getAllOrgForms(
        limit,
        offset,
        search_text
      );
      if (response?.status) {
        if (fetchdata) {
          fetchdata();
        }
        thunkAPI.dispatch(setLoadingFalse());
        thunkAPI.dispatch(setButtonLoadingTrue());
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      thunkAPI.dispatch(setButtonLoadingTrue());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
    thunkAPI.dispatch(setButtonLoadingTrue());
  }
);

export const createForm = createAsyncThunk(
  "create/form",
  async ({ data, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());

    try {
      const response = await orgforms.createOrgForm(data);
      if (response?.data.status) {
        thunkAPI.dispatch(setLoadingFalse());

        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);
//  get form
export const getForm = createAsyncThunk(
  "get/form",
  async ({ id, handlenavigate }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    thunkAPI.dispatch(setButtonLoadingFalse());
    try {
      const response = await orgforms.getOrgForm(id);
      if (response?.data.id) {
        thunkAPI.dispatch(setLoadingFalse());
        handlenavigate();
        CustomNotification("success", "Success", response.data.status);
        return response?.data;
      }
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      return error;
    }
    thunkAPI.dispatch(setLoadingFalse());
  }
);

export const fetchOrgFormNames = createAsyncThunk(
  "org/fetchOrgFormNames",
  async ({ limit, offset, search_text, fetchdata }, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTrue());
    try {
      const response = await orgforms.getOrgFormNames(
        limit,
        offset,
        search_text
      );
      if (fetchdata) {
        fetchdata();
      }
      thunkAPI.dispatch(setLoadingFalse());
      // CustomNotification(
      //   "success",
      //   "Success",
      //   "Form names fetched successfully"
      // );
      return response;
    } catch (error) {
      thunkAPI.dispatch(setLoadingFalse());
      CustomNotification("error", "Error", "Failed to fetch form names");
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const formSlice = createSlice({
  name: "form",
  initialState: {
    formDatalist: [],
    formDatainfo: [],
    loading: false,
    formNames: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFormData.fulfilled, (state, action) => {
        state.loading = false;
        state.formDatalist = action.payload;
      })
      .addCase(fetchFormData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getForm.pending, (state) => {
        state.loading = true;
      })
      .addCase(getForm.fulfilled, (state, action) => {
        state.loading = false;
        state.formDatainfo = action.payload;
      })
      .addCase(getForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchOrgFormNames.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrgFormNames.fulfilled, (state, action) => {
        state.loading = false;
        state.formNames = action.payload;
      })
      .addCase(fetchOrgFormNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // Additional cases for other form-related actions can be added here
  },
});

export default formSlice.reducer;
